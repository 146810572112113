import Web3 from "web3";
import { connect } from "react-redux";
import * as actions from "../src/actions";
import Hourglass_eth from "./Hourglass_eth.json";
import Hourglass_bnb from "./Hourglass_bnb.json";

const web3_obj = {
  contracts: {},
  accounts: [],
  feeLimit: 400000,
  callValue: 0,
  contract_instace: null,
  web3_instance: null,
  networkId: null,

  init: async function () {
    const web3 = await getWeb3();
    this.web3_instance = web3;
    // Use web3 to get the user's accounts.
    const accounts = await web3.eth.getAccounts();
    this.accounts = [accounts];
    const networkId = await web3.eth.net.getId();
    var hourglass =
      networkId == 1 || networkId == 3 ? Hourglass_eth : Hourglass_bnb;
    this.networkId = networkId;
    const deployedNetwork = hourglass.networks[networkId];

    const instance = deployedNetwork
      ? new web3.eth.Contract(
          hourglass.abi,
          deployedNetwork && deployedNetwork.address
        )
      : null;
    this.contract_instace = instance;
    // var data = await this.contract_instace.methods.disableInitialStage().send(
    //   {
    //     from: this.accounts[0][0],
    //     gas: this.feeLimit,
    //   },
    //   (error, result) => {
    //     //pass
    //     console.log("success");
    //   }
    // );
    console.log("cetothis", this);
  },
  buyFunc: async function (trx, ref) {
    var ref_by = ref ? ref : this.accounts[0][0];
    console.log("eth", trx, ref_by);

    var data = await this.contract_instace.methods.buy(ref_by).send(
      {
        from: this.accounts[0][0],
        // gas: this.feeLimit,
        value: trx,
      },
      (error, result) => {
        //pass
      }
    );
  },
  approveFunc: async function (ceto, wrapped_ceto_address) {
    var data = await this.contract_instace.methods
      .approve(wrapped_ceto_address, ceto.toString())
      .send(
        {
          from: this.accounts[0][0],
          gas: this.feeLimit,
        },
        (error, result) => {
          //pass
        }
      );
  },
  refFunc: async function (hex_str) {
    await this.contract_instace.methods.setReferralName(hex_str).send(
      {
        gas: this.feeLimit,
        from: this.accounts[0][0],
      },
      (error, result) => {
        //pass
      }
    );
  },
  reinvestFunc: async function (
    autoReinvest,
    period,
    rewardPerInvocation,
    minimumDividendValue
  ) {
    const period_ = period;
    const rewardPerInvocation_ = rewardPerInvocation * 1e18;
    const minimumDividendValue_ = minimumDividendValue * 1e18;
    console.log(
      period_,
      rewardPerInvocation_.toString(),
      minimumDividendValue_.toString()
    );
    var data = await this.contract_instace.methods
      .reinvest(
        autoReinvest,
        period,
        rewardPerInvocation_.toString(),
        minimumDividendValue_.toString()
      )

      .send(
        {
          from: this.accounts[0][0],
          gas: this.feeLimit,
        },
        (error, result) => {
          //pass
        }
      );
  },
  stopAutoReinvestFunc: async function () {
    await this.contract_instace.methods.stopAutoReinvest().send(
      {
        gas: this.feeLimit,
        from: this.accounts[0][0],
      },
      (error, result) => {
        //pass
      }
    );
  },
  withdrawFunc: async function () {
    await this.contract_instace.methods.withdraw().send(
      {
        gas: this.feeLimit,
        from: this.accounts[0][0],
      },
      (error, result) => {
        //pass
        console.log(error);
      }
    );
  },
  sellFunc: async function (ceto) {
    var data = await this.contract_instace.methods.sell(ceto.toString()).send(
      {
        from: this.accounts[0][0],
        gas: this.feeLimit,
      },
      (error, result) => {
        //pass
      }
    );
  },
  transferFunc: async function (address, ceto) {
    await this.contract_instace.methods.transfer(address, ceto).send(
      {
        from: this.accounts[0][0],

        gas: this.feeLimit,
      },
      (error, result) => {
        //pass
        console.log("err", error);
      }
    );
  },
  getTokensFunc: async function () {
    var my_tokens = await this.contract_instace
      .myTokens()
      .call({
        from: this.accounts[0],
        feeLimit: this.feeLimit,
        callValue: this.callValue || 0,
      })
      .then(function (res) {
        if (res) {
          return Promise.resolve(res.toString());
        }
      });
  },
};

const getWeb3 = () =>
  new Promise((resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    // console.log("check", window.ethereum.target);

    async function setup() {
      // Modern dapp browsers...
      console.log("dbj", window.ethereum);

      if (window.ethereum) {
        console.log("check1");

        const web3 = new Web3(window.ethereum);
        try {
          // Request account access if needed
          await window.ethereum.enable();
          // Acccounts now exposed
          resolve(web3);
        } catch (error) {
          reject(error);
        }
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        // Use Mist/MetaMask's provider.
        console.log("hey");

        const web3 = window.web3;
        console.log("Injected web3 detected.");
        resolve(web3);
      }
      // Fallback to localhost; use dev console port by default...
      else {
        console.log("hey");
        const provider = new Web3.providers.HttpProvider(
          `https://ropsten.infura.io/v3/05d456dfebc94ca682a215ed73faad06`
        );
        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();

        console.log(
          "No web3 instance injected, using Local web3.",
          accounts,
          networkId
        );
        resolve(web3);
      }
    }
    setup();
  });

export default web3_obj;
