import Web3 from "web3";
import { connect } from "react-redux";
import * as actions from "../src/actions";
import Vault_bnb from "./Vault_bnb.json";
import Vault_eth from "./Vault_eth.json";

const wceto_web3_obj = {
  contracts: {},
  accounts: [],
  feeLimit: 400000,
  callValue: 0,
  contract_instace: null,
  web3_instance: null,
  networkId: null,

  init: async function () {
    const web3 = await getWeb3();
    this.web3_instance = web3;
    // Use web3 to get the user's accounts.
    const accounts = await web3.eth.getAccounts();
    this.accounts = [accounts];
    const networkId = await web3.eth.net.getId();
    this.networkId = networkId;
    var Vault = networkId == 1 || networkId == 3 ? Vault_eth : Vault_bnb;
    const deployedNetwork = Vault.networks[networkId];

    const instance = deployedNetwork
      ? new web3.eth.Contract(
          Vault.abi,
          deployedNetwork && deployedNetwork.address
        )
      : null;
    this.contract_instace = instance;
    console.log("this", this);
  },
  buyFunc: async function (ceto) {
    var data = await this.contract_instace.methods.buy(ceto.toString()).send(
      {
        from: this.accounts[0][0],
        gas: this.feeLimit,
      },
      (error, result) => {
        console.log(error, "error");
      }
    );
  },

  sellFunc: async function (wceto) {
    
    var data = await this.contract_instace.methods.sell(wceto).send(
      {
        from: this.accounts[0][0],
        gas: this.feeLimit,
      },
      (error, result) => {
        //pass
        console.log("err", error);
      }
    );
  },
};

const getWeb3 = () =>
  new Promise((resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    // console.log("check", window.ethereum.target);

    async function setup() {
      // Modern dapp browsers...
      console.log("dbj", window.ethereum);

      if (window.ethereum) {
        console.log("check1");

        const web3 = new Web3(window.ethereum);
        try {
          // Request account access if needed
          await window.ethereum.enable();
          // Acccounts now exposed
          resolve(web3);
        } catch (error) {
          reject(error);
        }
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        // Use Mist/MetaMask's provider.
        console.log("hey");

        const web3 = window.web3;
        console.log("Injected web3 detected.");
        resolve(web3);
      }
      // Fallback to localhost; use dev console port by default...
      else {
        console.log("hey");
        const provider = new Web3.providers.HttpProvider(
          `https://ropsten.infura.io/v3/05d456dfebc94ca682a215ed73faad06`
        );
        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();

        console.log(
          "No web3 instance injected, using Local web3.",
          accounts,
          networkId
        );
        resolve(web3);
      }
    }
    setup();
  });

export default wceto_web3_obj;
