import { PROFILE_SET, PROFILE_RESET } from "../actions/types";

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROFILE_SET:
      return action.payload;
    case PROFILE_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
