import styled from "styled-components";
export const Styles = styled.div`
  .currencies_container {
    background-color: #fff;
    box-shadow: 0px 0px 40px #7090b026;
    width: 18.22vw;
    /* padding: 20px; */
    border-radius: 20px;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 600px) {
        width:100%;
        margin : 2rem 0;
    }
    .timediv {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      background-color: #162752;
      padding: 17px 60px 16px 60px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 40px #7090B0BF;
    }
    .price {
      color: #a1b5e0;
      font-size: 28px;

      text-align: center;
      font-weight: 300;
    }

    .countdown {
      display: flex;
      justify-content: center;
      

      .cd {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
        .c {
          color:white;
          font-size: 54px;
          font-weight: 300;
        }

        .d {
          color: #a1b5e0;
          font-size: 17px;
          font-weight: 500;
        }
      }
    }

    .cconvert {
      display: flex;
      flex-direction: column;
      align-items: center;
      .pc {
        display: flex;
        margin-bottom: 10px;

        .amount {
          background-color: #ffffff;
          border-radius: 50px;
          outline: none;
          color: #435475;
          width: 50%;
          height: 60px;
          padding: 18px 25px;
          border: 1px solid #bac4d7;
          font-size: 17px;
          font-weight: 500;
          font-family: "Poppins", sans-serif;
          max-width: 113px;
          margin-right: 5px;
        }
        .acurrency {
          background-color: #e9edf7;
          border-radius: 50px;
          border: none;
          outline: none;
          color: #435475;
          width: 50%;
          height: 60px;
          padding: 18px 25px;
          font-size: 17px;
          font-weight: 500;
          font-family: "Poppins", sans-serif;
          width: 172px;
        }
      }
    }
  }
`;
