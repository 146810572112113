import React, { useState, useEffect } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles, useStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";

import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import logo from "../../public/Button - Icon (1).svg";
import copy from "../../public/Group 5211.svg";
import WarningIcon from "@material-ui/icons/Warning";

const styles = {
  resize: {
    fontSize: 16,
  },
};

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const Transferpopup = ({
  open,
  handleClose,
  transfer,
  receipeintAddress,
  cryptoToTokens,
  totalLoss,
  crypto,
  accBalance,
  blockchain,
  factor,
}) => {
  // const [open, setmodal] = useState(true);
  const [bal, setbal] = useState(true);
  const [slug, setslug] = useState("");

  const [isavailable, setavail] = useState(true);

  const handleChange = e => {
    const { name, value } = e.target;
    setslug(value);
  };
  return (
    <Card>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            <span className="custom">Share the wealth.</span>

            <CloseIcon
              onClick={handleClose}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>
          <div className="midwrapper">
            <div className="rwrap">
              <div className="topd">
                <div className="cl">Recipient Wallet</div>
              </div>

              <div className="slug-txt-input">
                <span className="slug-val">{receipeintAddress}</span>
                <div className="slug-check"> </div>
              </div>

              <div className="topd" style={{ marginTop: "30px" }}>
                <div className="cl">Transfer Amount</div>
                {/* <div className="rd">50.13 TRX/$3,000 USD</div> */}
              </div>

              <div className="slug-txt-input">
                <span className="slug-val">{cryptoToTokens}</span>
                <div className="slug-check">
                  {blockchain == "eth"
                    ? "ECETO"
                    : blockchain == "bnb"
                    ? "BCETO"
                    : "CETO"}
                </div>
              </div>
              <div className="fee">
                <div className="f1">
                  <div className="cl">Transaction Fees</div>
                  <div className="slug-check">
                    {cryptoToTokens / 10}{" "}
                    {blockchain == "eth"
                      ? "ECETO"
                      : blockchain == "bnb"
                      ? "BCETO"
                      : "CETO"}
                  </div>
                </div>

                <hr />
                <div className="f1">
                  <div className="cl">Net Transfer</div>
                  <div className="slug-check">
                    {crypto && crypto / factor}{" "}
                    {blockchain == "eth"
                      ? "ECETO"
                      : blockchain == "bnb"
                      ? "BCETO"
                      : "CETO"}
                  </div>
                </div>
              </div>
              <div className="cntner">
                {blockchain == "trx" && accBalance / factor < 28 && (
                  <div className="warn-line">
                    {" "}
                    <WarningIcon
                      style={{ margin: "-6px auto", color: "#de3c3c" }}
                    ></WarningIcon>{" "}
                    <span>
                      Insufficient TRX in your wallet for transaction.
                    </span>
                  </div>
                )}
                <div className="action-div">
                  <GreenButton
                    style={{
                      background: "#ffffff",
                      color: "#691AAA",
                      border: "1px solid #691AAA",

                      marginRight: "15px",
                      width: "210px",
                    }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </GreenButton>
                  <GreenButton
                    style={{
                      background: " #691AAA",
                      color: "#ffffff",

                      width: "200px",
                    }}
                    onClick={() => {
                      handleClose();
                      transfer();
                    }}
                  >
                    Confirm
                  </GreenButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Transferpopup;
