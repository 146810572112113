import { combineReducers } from "redux";
import UsersReducer from "./userreducer";
import UsersProfileReducer from "./userprofile";
import UtilsReducer from "./utilsReducer"

const rootReducer = combineReducers({
  user: UsersReducer,
  userProfile: UsersProfileReducer,
  utils : UtilsReducer
});

export default rootReducer;
