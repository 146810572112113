import React, { useState } from "react";
import Navbar from "../components/navbar/navbar.component";
import Footer from "../components/footer/footer.component";
import styles from "./../styles/faq.module.scss";
// 3rd party libs.
import ReactHtmlParser from "react-html-parser";
// Mapped Data for landing page
import QUESTIONS_ARRAY from "../assets/landing/questions";
// Material-UI
import { IconButton } from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import eth from "../public/ethe.png";
import bnb from "../public/bnb.png";
import CinemaDraftButton from "../components/cinema-draft-button/cinema-draft-button.component";
import { connect } from "react-redux";
import * as actions from "../actions";
import ArrowInButton from "../public/herosection-button-icon.svg";
import Banner from "../components/banner";
const Faq = props => {
  const [questionsArray, setQuestionsArray] = useState(QUESTIONS_ARRAY);

  // Function which toggles the question & answers
  const toggleQA = (e, idx) => {
    setQuestionsArray(questionsArray => {
      const dummyArr = questionsArray;

      dummyArr[idx].isOpen = !dummyArr[idx].isOpen;

      return [...dummyArr];
    });
  };
  const getColor = type => {
    if (type == 0) {
      if (props.blockchain == "trx") {
        return "#012d8c";
      } else if (props.blockchain == "bnb") {
        return "#BF9C00";
      } else {
        return "#464646";
      }
    }
  };
  return (
    <>
      <Banner {...props}></Banner>

      <Navbar {...props} blockchain={props.utils.blockchain} />

      <div className={styles.social__body}>
        <div className={styles.questionsContainer}>
          <span className={styles.qhead} style={{ color: getColor(0) }}>
            Rules of the Jungle
          </span>

          <div className={styles.questionsBlock}>
            {questionsArray.map((question, idx) => (
              <React.Fragment key={`QA_${idx}`}>
                <div className={styles.question}>
                  {question.question}
                  {question.isOpen ? (
                    <IconButton onClick={e => toggleQA(e, idx)}>
                      <RemoveCircleIcon style={{ color: " #009EE4" }} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={e => toggleQA(e, idx)}>
                      <AddCircleIcon style={{ color: "#691AAA" }} />
                    </IconButton>
                  )}
                </div>
                {question.isOpen && (
                  <div className={styles.answer}>
                    {ReactHtmlParser(question.answer)}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <Footer blockchain={props.utils.blockchain} />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Faq);
