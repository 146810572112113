import { CURRENCIES, SET_ENV, BLOCKCHAIN, STATS } from "../actions/types";

const initialState = {
  currencies: {},
  contract_instace: null,
  accounts: [],
  contract_balance: null,
  blockchain: "trx",
  eth_liquidity: null,
  bnb_liquidity: null,
  trx_liquidity: null,
  bnb_in_contract: null,
  eth_in_contract: null,
  trx_in_contract: null,
  actual_bnb_in_contract: null,
  actual_eth_in_contract: null,
};

export default function UtilsReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENCIES:
      // console.log("success");
      var newState = {
        ...state,
        currencies: action.payload,
      };
      return newState;

    case STATS:
      // console.log("success");
      var newState = {
        ...state,
        eth_liquidity: action.payload.eth_liquidity,
        bnb_liquidity: action.payload.bnb_liquidity,
        trx_liquidity: action.payload.trx_liquidity,
        bnb_in_contract: action.payload.bnb_in_contract,
        eth_in_contract: action.payload.eth_in_contract,
        trx_in_contract: action.payload.trx_in_contract,
        actual_bnb_in_contract: action.payload.actual_bnb_in_contract,
        actual_eth_in_contract: action.payload.actual_eth_in_contract,
      };
      return newState;

    case SET_ENV:
      // console.log(action.payload,"success");
      var newState = {
        ...state,
        contract_instace: action.payload.instance,
        contract_balance: action.payload.contract_balance,
        accounts: action.payload.accounts,
      };
      return newState;
    case BLOCKCHAIN:
      var newState = {
        ...state,
        blockchain: action.payload,
      };

      return newState;
    default:
      return state;
  }
}
