import React, { useState } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./styles";
import exchange from "../../public/Exchange - Icon.svg";
import arrow from "../../public/Button - Icon.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import auto_ele from "../../public/auto_ele.png";
// import { FALSE } from "node-sass";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const AutoReinvestModal = ({
  show_auto_reinvest_popup,
  set_show_auto_reinvest_popup,
  blockchain,
}) => {
  return (
    <Card>
      <div className={`modal ${show_auto_reinvest_popup ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            Feed the beast.
            <CloseIcon
              onClick={() => set_show_auto_reinvest_popup(false)}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>

          <div className="middiv">
            <img src={auto_ele}></img>
            <p className="warn">
              Confirming this will auto-reinvest your dividends every 24 hours{" "}
            </p>
            <p className="inf">You can stop auto-reinvest anytime</p>
            <div className="infor">
              <div className="box-1 brdr">
                <div className="txt1">
                  Divs &ge;{" "}
                  {blockchain && blockchain == "trx"
                    ? 50
                    : blockchain && blockchain == "eth"
                    ? 0.25
                    : 8}{" "}
                  {blockchain && blockchain.toUpperCase()}
                </div>
                <div className="txt2">
                  Reinvest the rest Charge :{" "}
                  {blockchain && blockchain == "trx"
                    ? 20
                    : blockchain && blockchain == "eth"
                    ? 0.025
                    : 0.8}{" "}
                  {blockchain && blockchain.toUpperCase()}
                </div>
              </div>
              {/* <hr></hr> */}
              <div className="box-1">
                <div className="txt1 lft">{`Divs < ${
                  blockchain && blockchain == "trx"
                    ? 50
                    : blockchain && blockchain == "eth"
                    ? 0.25
                    : 8
                } 
                  ${blockchain && blockchain.toUpperCase()}`}</div>
                <div className="txt2 lft">
                  Retries with more {blockchain && blockchain.toUpperCase()} in
                  next 24 hours
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AutoReinvestModal;
