import React, { useEffect, useState } from "react";
import axios from "axios";
import { browserHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@material-ui/icons/Clear";
import { Button, notification, Divider, Space } from "antd";
import "antd/dist/antd.css";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./recent-transactions.scss";
import sell from "../../public/Sell-Withdraw - Icon.svg";
import buy from "../../public/Buy-Reinvest - Icon.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import metacoinConfig from "../../metacoin-config";
import Contract_obj from "../../getTronweb";
// import { Pagination } from "antd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Empty } from "antd";
import Hourglass_eth from "../../Hourglass_eth.json";
import Hourglass_bnb from "../../Hourglass_bnb.json";
import Snackbar from "@material-ui/core/Snackbar";
import web3_obj from "../../getWeb3";
import TronGrid from "trongrid";
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  ul: {
    ".MuiPagination-ul": {
      backgroundColor: "#691AAA",
    },
  },
}));
const Transactions = ({
  state,
  type,
  updated,
  setUpdated,
  history,
  ...props
}) => {
  const [txns, setTxns] = useState([]);
  const [txns_to_show, set_txns_to_show] = useState([]);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState(0);
  const [count, setCount] = useState(0);
  const [current_timestamp, setCurrentTimestamp] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ids, setIds] = useState([]);
  const [node, setNode] = useState();
  const [factor, setFactor] = useState();
  const [path, setPath] = useState(
    window.location.pathname == "transactions" ||
      window.location.pathname == "/transactions"
      ? 1
      : 0
  );
  const [notif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [positions, setPositions] = React.useState({
    vertical: "bottom",
    horizontal: "right",
  });

  const { vertical, horizontal } = positions;
  const classes = useStyles();

  const getTransactions = async () => {
    console.log("trx");
    var node__ =
      window.tronWeb &&
      window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
        ? "shasta"
        : "mainnet";
    setNode(node__);
    if (
      Contract_obj.tronWebProvider &&
      Contract_obj.tronWebProvider.defaultAddress.hex
    ) {
      var user_ =
        "0x" + Contract_obj.tronWebProvider.defaultAddress.hex.slice(2);
      console.log(user_, "hexbvbk");
      var config = {
        method: "get",
        url: `https://api.crystalelephant.net/api/transaction/?filter=result__customerAddress=${user_}%26event_name=onReinvestment,result__customerAddress=${user_}%26event_name=onWithdraw,result__customerAddress=${user_}%26event_name=onTokenPurchase,result__customerAddress=${user_}%26event_name=onTokenSell,result__from=${user_}%26event_name=Transfer`,
        headers: {},
      };
      axios(config)
        .then(function (response) {
          var temp = response.data.data;
          console.log(temp, "vbkd");
          setTxns(temp);
        })
        .catch(err => {
          console.log(err, "err");

          setLoading(false);
        });
    }

    setLoading(false);
  };
  const getEthTransactions = () => {
    if (
      web3_obj.networkId &&
      (web3_obj.networkId == 3 || web3_obj.networkId == 1)
    ) {
      var temp;
      var topics_arr = [
        "0x022c0d992e4d873a3748436d960d5140c1f9721cf73f7ca5ec679d3d9f4fe2d5",
        "0xbe339fc14b041c2b0e0f3dd2cd325d0c3668b78378001e53160eab3615326458",
        "0xc4823739c5787d2ca17e404aa47d5569ae71dfb49cbf21b3f6152ed238a31139",
        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
        "0xccad973dcd043c7d680389db4378bd6b9775db7124092e9e0422c9e46d7985dc",
      ];
      var topics_obj = {
        "0x022c0d992e4d873a3748436d960d5140c1f9721cf73f7ca5ec679d3d9f4fe2d5":
          "onTokenPurchase",
        "0xbe339fc14b041c2b0e0f3dd2cd325d0c3668b78378001e53160eab3615326458":
          "onReinvestment",
        "0xc4823739c5787d2ca17e404aa47d5569ae71dfb49cbf21b3f6152ed238a31139":
          "onTokenSell",
        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef":
          "Transfer",
        "0xccad973dcd043c7d680389db4378bd6b9775db7124092e9e0422c9e46d7985dc":
          "onWithdraw",
      };
      const Web3Utils = require("web3-utils");

      var config = {
        method: "get",
        url: `https://${
          web3_obj.networkId && web3_obj.networkId == 3 ? "api-ropsten" : "api"
        }.etherscan.io/api?module=logs&action=getLogs&address=${
          Hourglass_eth.networks[web3_obj.networkId && web3_obj.networkId]
            .address
        }&apikey=52X7WJHXR2V8ZCIMG5A7FCD931XGFBV5XQ`,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          temp = response.data.result;

          // console.log(temp);

          temp = temp.filter(txn => topics_arr.includes(txn.topics[0]));
          temp = temp.filter(
            txn =>
              txn &&
              txn["topics"][1].slice(26) ==
                web3_obj.accounts[0][0].slice(2).toLowerCase()
          );

          temp.forEach(txnn => {
            txnn["block_timestamp"] =
              parseInt(Web3Utils.hexToNumberString(txnn.timeStamp)) * 1000;
            txnn["event_name"] = topics_obj[txnn.topics[0]];
            if (txnn["event_name"] == "onTokenPurchase") {
              console.log("hi");
              txnn["result"] = {};
              txnn["result"]["incomingTron"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"]["tokensMinted"] = Web3Utils.hexToNumberString(
                "0x" + txnn.data.slice(66)
              );
            } else if (txnn["event_name"] == "onReinvestment") {
              txnn["result"] = {};
              txnn["result"]["tronReinvested"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"]["tokensMinted"] = Web3Utils.hexToNumberString(
                "0x" + txnn.data.slice(66)
              );
            } else if (txnn["event_name"] == "onTokenSell") {
              txnn["result"] = {};
              txnn["result"]["tokensBurned"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"]["tronEarned"] = Web3Utils.hexToNumberString(
                "0x" + txnn.data.slice(66)
              );
            } else if (txnn["event_name"] == "onWithdraw") {
              txnn["result"] = {};
              txnn["result"]["tronWithdrawn"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
            } else if (txnn["event_name"] == "Transfer") {
              console.log(txnn, "TXNN", txnn.topics[1].slice(26));
              txnn["result"] = {};
              txnn["result"]["tokens"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"][0] = "0x" + txnn.topics[1].slice(26);
            }
          });
          if (temp.length > 1) {
            var temp_len = temp.length - 1;
            var filtered = temp;
            var i = 0;

            while (i < temp_len) {
              if (
                temp[i].transactionHash == temp[i + 1].transactionHash &&
                temp[i].event_name == "Transfer" &&
                temp[i + 1].event_name == "onTokenPurchase"
              ) {
                filtered = filtered.filter(val => val != temp[i + 1]);
                i += 2;
              } else if (
                temp[i].transactionHash == temp[i + 1].transactionHash
              ) {
                filtered = filtered.filter(val => val != temp[i]);
                i += 2;
              } else if (
                temp[i].transactionHash == temp[i + 1].transactionHash &&
                temp[i].event_name == temp[i + 1].event_name
              ) {
                filtered = filtered.filter(val => val != temp[i]);
                i += 2;
              } else {
                i += 1;
              }
            }
            temp = filtered;
          }

          console.log(temp, "eth_txns");
          console.log(
            web3_obj.accounts[0][0].slice(2),
            response.data.result[0].topics[1].slice(26)
          );
          console.log(Web3Utils.hexToNumberString("0x602bb303"), "..");
          temp.sort((a, b) =>
            a.block_timestamp < b.block_timestamp
              ? 1
              : b.block_timestamp < a.block_timestamp
              ? -1
              : 0
          );
          setTxns(temp);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setLoading(false);
    }
  };
  const getBnbTransactions = () => {
    if (
      web3_obj.networkId &&
      (web3_obj.networkId == 97 || web3_obj.networkId == 56)
    ) {
      var temp;
      var topics_arr = [
        "0x022c0d992e4d873a3748436d960d5140c1f9721cf73f7ca5ec679d3d9f4fe2d5",
        "0xbe339fc14b041c2b0e0f3dd2cd325d0c3668b78378001e53160eab3615326458",
        "0xc4823739c5787d2ca17e404aa47d5569ae71dfb49cbf21b3f6152ed238a31139",
        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
        "0xccad973dcd043c7d680389db4378bd6b9775db7124092e9e0422c9e46d7985dc",
      ];
      var topics_obj = {
        "0x022c0d992e4d873a3748436d960d5140c1f9721cf73f7ca5ec679d3d9f4fe2d5":
          "onTokenPurchase",
        "0xbe339fc14b041c2b0e0f3dd2cd325d0c3668b78378001e53160eab3615326458":
          "onReinvestment",
        "0xc4823739c5787d2ca17e404aa47d5569ae71dfb49cbf21b3f6152ed238a31139":
          "onTokenSell",
        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef":
          "Transfer",
        "0xccad973dcd043c7d680389db4378bd6b9775db7124092e9e0422c9e46d7985dc":
          "onWithdraw",
      };
      const Web3Utils = require("web3-utils");
      console.log(web3_obj.networkId, "vbfk");
      var config = {
        method: "get",
        url: `https://${
          web3_obj.networkId && web3_obj.networkId == 97 ? "api-testnet" : "api"
        }.bscscan.com/api?module=logs&action=getLogs&address=${
          Hourglass_bnb.networks[web3_obj.networkId && web3_obj.networkId]
            .address
        }&apikey=MCWX55EETDKPIDXJ3D1NCAN8T24FUSD4CK`,
      };

      axios(config)
        .then(function (response) {
          temp = response.data.result;
          // console.log(response, "bnb");
          temp = temp.filter(txn => topics_arr.includes(txn.topics[0]));
          temp = temp.filter(
            txn =>
              txn.topics[1].slice(26) ==
              web3_obj.accounts[0][0].slice(2).toLowerCase()
          );

          temp.forEach(txnn => {
            txnn["block_timestamp"] =
              parseInt(Web3Utils.hexToNumberString(txnn.timeStamp)) * 1000;
            txnn["event_name"] = topics_obj[txnn.topics[0]];
            if (txnn["event_name"] == "onTokenPurchase") {
              console.log("hi");
              txnn["result"] = {};
              txnn["result"]["incomingTron"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"]["tokensMinted"] = Web3Utils.hexToNumberString(
                "0x" + txnn.data.slice(66)
              );
            } else if (txnn["event_name"] == "onReinvestment") {
              txnn["result"] = {};
              txnn["result"]["tronReinvested"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"]["tokensMinted"] = Web3Utils.hexToNumberString(
                "0x" + txnn.data.slice(66)
              );
            } else if (txnn["event_name"] == "onTokenSell") {
              txnn["result"] = {};
              txnn["result"]["tokensBurned"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"]["tronEarned"] = Web3Utils.hexToNumberString(
                "0x" + txnn.data.slice(66)
              );
            } else if (txnn["event_name"] == "onWithdraw") {
              txnn["result"] = {};
              txnn["result"]["tronWithdrawn"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
            } else if (txnn["event_name"] == "Transfer") {
              console.log(txnn, "TXNN", txnn.topics[1].slice(26));
              txnn["result"] = {};
              txnn["result"]["tokens"] = Web3Utils.hexToNumberString(
                txnn.data.slice(0, 66)
              );
              txnn["result"][0] = "0x" + txnn.topics[1].slice(26);
            }
          });
          if (temp.length > 1) {
            var temp_len = temp.length - 1;
            var filtered = temp;
            var i = 0;

            while (i < temp_len) {
              if (
                temp[i].transactionHash == temp[i + 1].transactionHash &&
                temp[i].event_name == "Transfer" &&
                temp[i + 1].event_name == "onTokenPurchase"
              ) {
                filtered = filtered.filter(val => val != temp[i + 1]);
                i += 2;
              } else if (
                temp[i].transactionHash == temp[i + 1].transactionHash
              ) {
                filtered = filtered.filter(val => val != temp[i]);
                i += 2;
              } else if (
                temp[i].transactionHash == temp[i + 1].transactionHash &&
                temp[i].event_name == temp[i + 1].event_name
              ) {
                filtered = filtered.filter(val => val != temp[i]);
                i += 2;
              } else {
                i += 1;
              }
            }
            temp = filtered;
          }
          console.log(temp, "eth_txns");
          console.log(
            web3_obj.accounts[0][0].slice(2),
            response.data.result[0].topics[1].slice(26)
          );
          console.log(Web3Utils.hexToNumberString("0x602bb303"), "..");
          temp.sort((a, b) =>
            a.block_timestamp < b.block_timestamp
              ? 1
              : b.block_timestamp < a.block_timestamp
              ? -1
              : 0
          );
          setTxns(temp);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPaginator();
    localStorage.setItem(
      "latest_timestamp",
      txns[0] && txns[0].block_timestamp
    );
  }, [txns]);
  useEffect(() => {
    setLoading(true);
    if (props.utils.blockchain == "trx") {
      setFactor(1e6);
      window.tronWeb && window.tronWeb.defaultAddress.hex
        ? getTransactions()
        : setLoading(false);
      console.log("UPDATED");
    } else if (props.utils.blockchain == "eth") {
      setFactor(1e18);
      web3_obj.contract_instace && web3_obj.accounts[0][0]
        ? getEthTransactions()
        : setLoading(false);
    } else {
      setFactor(1e18);
      web3_obj.contract_instace && web3_obj.accounts[0][0]
        ? getBnbTransactions()
        : setLoading(false);
    }
  }, [updated, props.utils.blockchain]);

  const getPaginator = () => {
    var txn = txns_to_show;
    var new_txns =
      path == 1
        ? txns.slice(0, paginator * 10 + 10)
        : txns.slice(0, paginator * 5 + 5);

    set_txns_to_show(new_txns);
    setPaginator(paginator + 1);
  };

  const getEvents = async () => {
    let contract = Contract_obj.contract_instace;

    var placement = "bottomRight";

    (await contract) &&
      contract.onTokenPurchase().watch((err, event) => {
        if (err) return console.error('Error with "Message" event:', err);

        if (
          event &&
          window.tronWeb.address.fromHex(event.result.customerAddress) !=
            Contract_obj.accounts[0]
        ) {
          setNotifMsg(
            (parseInt(event.result.incomingTron) / 1e6).toFixed(2).toString()
          );
          setNotif(true);
        } else {
          // setUpdated(updated + 1);
        }
        setUpdated(updated + 1);

        console.group("New event received", event);
        console.log("- event:", event.contract);
        console.groupEnd();
      });
    (await contract) &&
      contract.onTokenSell().watch((err, event) => {
        if (err) return console.error('Error with "Message" event:', err);
        if (event) {
          setUpdated(updated + 1);
        }

        console.group("New event received");
        console.log("- event: sell");
        console.groupEnd();
      });
    (await contract) &&
      contract.onReinvestment().watch((err, event) => {
        if (err) return console.error('Error with "Message" event:', err);
        if (event) {
          setUpdated(updated + 1);
        }

        console.group("New event received");
        console.log("- event: reinvest");
        console.groupEnd();
      });
    (await contract) &&
      contract.onAutoReinvestmentStop().watch((err, event) => {
        if (err) return console.error('Error with "Message" event:', err);
        if (event) {
          setUpdated(updated + 1);
        }

        console.group("New event received");
        console.log("- event: onAutoReinvestmentStop");
        console.groupEnd();
      });
    (await contract) &&
      contract.Transfer().watch((err, event) => {
        if (err) return console.error('Error with "Message" event:', err);
        if (event) {
          setUpdated(updated + 1);
        }

        console.group("New event received");
        console.log("- event: Transfer");
        console.groupEnd();
      });
    (await contract) &&
      contract.onWithdraw().watch((err, event) => {
        if (err) return console.error('Error with "Message" event:', err);
        if (event) {
          setUpdated(updated + 1);
        }

        console.group("New event received");
        console.log("- event: onWithdraw");
        console.groupEnd();
      });
  };
  useEffect(() => {
    setLoading(true);

    if (props.utils.blockchain == "eth") {
      setFactor(1e18);
      web3_obj.contract_instace && web3_obj.accounts[0][0]
        ? getEthTransactions()
        : setLoading(false);
    } else if (props.utils.blockchain == "bnb") {
      setFactor(1e18);
      web3_obj.contract_instace && web3_obj.accounts[0][0]
        ? getBnbTransactions()
        : setLoading(false);
    } else {
      const loadWatcher = setInterval(async () => {
        console.log("metaconfig", metacoinConfig);

        if (window.tronWeb && window.tronWeb.ready) {
          clearInterval(loadWatcher);
          setFactor(1e6);

          // window.tronWeb && Contract_obj.contract_instace && getEvents();
          var node_ =
            window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
              ? "shasta"
              : "mainnet";
          setNode(node_);
          setPaginator(0);
          console.log("metaconfig", metacoinConfig);
          window.tronWeb &&
          Contract_obj.contract_instace &&
          window.tronWeb.defaultAddress.hex
            ? getTransactions()
            : setLoading(false);
        } else {
          setLoading(false);
        }
      }, 2000);
    }
  }, []);
  return (
    <div
      className={`transactions`}
      style={{ marginTop: state === 4 && "55px" }}
    >
      <div className={`topdv`} style={{ marginTop: state === 4 && "50px" }}>
        <span
          className={`title`}
          style={{
            color:
              props.blockchain == "eth"
                ? "#464646"
                : props.blockchain == "bnb"
                ? "#BF9C00"
                : "#002E8C",
          }}
        >
          {state === 4 ? "Transactions" : " Recent Transactions"}
        </span>

        {/* <div className="rd">
          {state === 4 ? (
            <a
              // href={
              //   node &&
              //   `${metacoinConfig[node].url}/#/contract/${metacoinConfig[node].contractAddressBase}/transactions`
              // }
              target="_blank"
            >
              All CETO Transactions
            </a>
          ) : (
            <span
              style={{
                color:
                  props.blockchain == "eth" || props.blockchain == "bnb"
                    ? "#691AAA"
                    : "#009EE4",
              }}
              // onClick={() => history.replace({ pathname: "/transactions" })}
            >
              See all transactions
            </span>
          )}
          <ArrowForwardIosIcon
            style={{
              fontSize: "12px",
              marginTop: "3px",
              marginLeft: "5px",
              color:
                props.blockchain == "eth" || props.blockchain == "bnb"
                  ? "#691AAA"
                  : "#009EE4",
            }}
          />
        </div>
       */}
      </div>

      <div
        className={`transactionContainer ${type ? "withborder" : ""}`}
        style={{
          backgroundColor: props.blockchain == "eth" ? "#EBEBEB" : "white",
        }}
      >
        <div className="transactionContainer__header">
          <span>Date</span>
          <span className="only_l">Type</span>
          <span className="only_l">Transaction No.</span>
          <span style={{ textTransform: "uppercase" }}>
            {props.utils.blockchain}
          </span>
          <span>
            {" "}
            {props.utils.blockchain == "eth"
              ? "ECETO"
              : props.utils.blockchain == "bnb"
              ? "BCETO"
              : "CETO"}
          </span>
        </div>
        {/* {txns.length} */}
        {!loading ? (
          txns.length > 0 ? (
            state === 4 ? (
              <>
                <div
                  className="transactionContainer__transactionRows"
                  style={{ justifyContent: "start", minHeight: "50vh" }}
                >
                  {txns &&
                    txns.slice((page - 1) * 10, page * 10).map(txnn => {
                      return (
                        <div
                          key={txnn.txID}
                          className="transactionContainer__transactionRow"
                        >
                          {/* <span> */}
                          <span
                            style={{
                              // width: "200px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <img
                                src={
                                  txnn &&
                                  txnn.event_name &&
                                  [
                                    "onTokenPurchase",
                                    "onReinvestment",
                                  ].includes(txnn.event_name)
                                    ? buy
                                    : sell
                                }
                                width="28"
                                height="28"
                              /> */}

                            <span>
                              {new Date(txnn.block_timestamp).toDateString()}
                            </span>
                          </span>
                          {/* </span> */}

                          <span className="only_l">
                            {txnn &&
                              props.utils.blockchain == "trx" &&
                              (txnn.event_name == "onTokenPurchase"
                                ? "Buy"
                                : txnn.event_name == "onTokenSell"
                                ? "Sell"
                                : txnn.event_name == "onWithdraw"
                                ? "Withdraw"
                                : txnn.event_name == "onReinvestment"
                                ? "Reinvest"
                                : Contract_obj.tronWebProvider.defaultAddress
                                    .hex &&
                                  txnn.result[0].slice(2) ==
                                    Contract_obj.tronWebProvider.defaultAddress.hex.slice(
                                      2
                                    )
                                ? "Transfer Out"
                                : "Transfer In")}
                            {txnn &&
                              (props.utils.blockchain == "eth" ||
                                props.utils.blockchain == "bnb") &&
                              (txnn.event_name == "onTokenPurchase"
                                ? "Buy"
                                : txnn.event_name == "onTokenSell"
                                ? "Sell"
                                : txnn.event_name == "onWithdraw"
                                ? "Withdraw"
                                : txnn.event_name == "onReinvestment"
                                ? "Reinvest"
                                : web3_obj.accounts[0][0] &&
                                  txnn.result[0].slice(2) ==
                                    web3_obj.accounts[0][0].hex.slice(2)
                                ? "Transfer Out"
                                : "Transfer In")}
                          </span>
                          <a
                            className="only_l"
                            target="_blank"
                            href={
                              props.blockchain == "trx"
                                ? `${metacoinConfig["mainnet"].url}/#/transaction/${txnn.transaction_id}`
                                : props.blockchain == "eth"
                                ? `https://etherscan.io/tx/${
                                    txnn.transactionHash && txnn.transactionHash
                                  }`
                                : `https://bscscan.com/tx/${
                                    txnn.transactionHash && txnn.transactionHash
                                  }`
                            }
                          >
                            {props.utils.blockchain == "eth"
                              ? txnn.transactionHash &&
                                txnn.transactionHash.slice(0, 20)
                              : txnn.transaction_id &&
                                txnn.transaction_id.slice(0, 20)}
                            ...
                          </a>
                          <span>
                            {txnn &&
                            txnn.result &&
                            (txnn.result.incomingTron ||
                              txnn.result.tronEarned ||
                              txnn.result.tronReinvested ||
                              txnn.result.tronWithdrawn)
                              ? (
                                  (txnn.result.incomingTron ||
                                    txnn.result.tronEarned ||
                                    txnn.result.tronReinvested ||
                                    txnn.result.tronWithdrawn ||
                                    0) / factor
                                ).toFixed(2)
                              : "-"}{" "}
                            {(txnn.result.incomingTron ||
                              txnn.result.tronEarned ||
                              txnn.result.tronReinvested ||
                              txnn.result.tronWithdrawn) &&
                              props.utils.blockchain.toUpperCase()}
                          </span>
                          <span>
                            {txnn &&
                            txnn.result &&
                            (txnn.result.tokensMinted ||
                              txnn.result.tokensBurned ||
                              txnn.result.tokens)
                              ? (
                                  (txnn.result.tokensMinted ||
                                    txnn.result.tokensBurned ||
                                    txnn.result.tokens) / factor
                                ).toFixed(2)
                              : "-"}{" "}
                            {(txnn.result.tokensMinted ||
                              txnn.result.tokensBurned ||
                              txnn.result.tokens) &&
                              "CETO"}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div className="pagi">
                  <Pagination
                    style={{ color: "#691AAA" }}
                    count={count}
                    pagep={page}
                    onChange={(e, page) => {
                      setPage(page);
                    }}
                    color="primary"
                  />
                </div>
              </>
            ) : (
              <div
                className="transactionContainer__transactionRows"
                style={{ justifyContent: "start" }}
              >
                <InfiniteScroll
                  dataLength={path == 1 ? paginator * 10 : paginator * 5}
                  next={getPaginator}
                  hasMore={true}
                  height={path == 1 ? 600 : 330}
                  // loader={<h4>Loading...</h4>}
                >
                  {txns_to_show &&
                    txns_to_show.map(txnn => {
                      return (
                        <div
                          key={txnn.txID}
                          className="transactionContainer__transactionRow"
                        >
                          {/* <span> */}
                          <span
                            style={{
                              // width: "200px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <img
                                src={
                                  txnn &&
                                  txnn.event_name &&
                                  [
                                    "onTokenPurchase",
                                    "onReinvestment",
                                  ].includes(txnn.event_name)
                                    ? buy
                                    : sell
                                }
                                width="28"
                                height="28"
                              /> */}

                            <span>
                              {new Date(txnn.block_timestamp).toDateString()}
                            </span>
                          </span>
                          {/* </span> */}

                          <span className="only_l">
                            {txnn &&
                              props.utils.blockchain == "trx" &&
                              (txnn.event_name == "onTokenPurchase"
                                ? "Buy"
                                : txnn.event_name == "onTokenSell"
                                ? "Sell"
                                : txnn.event_name == "onWithdraw"
                                ? "Withdraw"
                                : txnn.event_name == "onReinvestment"
                                ? "Reinvest"
                                : Contract_obj.tronWebProvider.defaultAddress
                                    .hex &&
                                  txnn.result[0].slice(2) ==
                                    Contract_obj.tronWebProvider.defaultAddress.hex
                                      .slice(2)
                                      .toLowerCase()
                                ? "Transfer Out"
                                : "Transfer In")}
                            {txnn &&
                              txnn.result &&
                              (props.utils.blockchain == "eth" ||
                                props.utils.blockchain == "bnb") &&
                              (txnn.event_name == "onTokenPurchase"
                                ? "Buy"
                                : txnn.event_name == "onTokenSell"
                                ? "Sell"
                                : txnn.event_name == "onWithdraw"
                                ? "Withdraw"
                                : txnn.event_name == "onReinvestment"
                                ? "Reinvest"
                                : web3_obj.accounts[0][0] &&
                                  txnn.result[0].slice(2) ==
                                    web3_obj.accounts[0][0].slice(2)
                                ? "Transfer Out"
                                : "Transfer In")}
                          </span>
                          <a
                            className="only_l"
                            style={{
                              color:
                                props.blockchain == "eth" ||
                                props.blockchain == "bnb"
                                  ? "#162752"
                                  : "#009EE4",
                            }}
                            target="_blank"
                            href={
                              props.blockchain == "trx"
                                ? `${metacoinConfig["mainnet"].url}/#/transaction/${txnn.transaction_id}`
                                : props.blockchain == "eth"
                                ? `https://etherscan.io/tx/${
                                    txnn.transactionHash && txnn.transactionHash
                                  }`
                                : `https://bscscan.com/tx/${
                                    txnn.transactionHash && txnn.transactionHash
                                  }`
                            }
                          >
                            {props.utils.blockchain == "eth" ||
                            props.utils.blockchain == "bnb"
                              ? txnn.transactionHash &&
                                txnn.transactionHash.slice(0, 20)
                              : txnn.transaction_id &&
                                txnn.transaction_id.slice(0, 20)}
                            ...
                          </a>
                          <span style={{ textTransform: "uppercase" }}>
                            {txnn &&
                            txnn.result &&
                            (txnn.result.incomingTron ||
                              txnn.result.tronEarned ||
                              txnn.result.tronReinvested ||
                              txnn.result.tronWithdrawn)
                              ? (
                                  (txnn.result.incomingTron ||
                                    txnn.result.tronEarned ||
                                    txnn.result.tronReinvested ||
                                    txnn.result.tronWithdrawn ||
                                    0) / factor
                                ).toFixed(2)
                              : "-"}{" "}
                            {(txnn.result.incomingTron ||
                              txnn.result.tronEarned ||
                              txnn.result.tronReinvested ||
                              txnn.result.tronWithdrawn) &&
                              props.utils.blockchain}
                          </span>
                          <span>
                            {txnn &&
                            txnn.result &&
                            (txnn.result.tokensMinted ||
                              txnn.result.tokensBurned ||
                              txnn.result.tokens)
                              ? (
                                  (txnn.result.tokensMinted ||
                                    txnn.result.tokensBurned ||
                                    txnn.result.tokens) / factor
                                ).toFixed(2)
                              : "-"}{" "}
                            {(txnn.result.tokensMinted ||
                              txnn.result.tokensBurned ||
                              txnn.result.tokens) &&
                              `${
                                props.utils.blockchain == "eth"
                                  ? "ECETO"
                                  : props.utils.blockchain == "bnb"
                                  ? "BCETO"
                                  : "CETO"
                              }`}
                          </span>
                        </div>
                      );
                    })}
                </InfiniteScroll>
              </div>
            )
          ) : (
            <div style={{ justifyContent: "center", margin: " 22%" }}>
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span
                    style={{
                      display: "block",
                      color: "#7689b0",
                      textTransform: "capitalize",
                      marginBottom: " 1.2em",
                      fontSize: "18px",
                    }}
                  >
                    No Transactions
                  </span>
                }
              ></Empty>
            </div>
          )
        ) : (
          <div style={{ justifyContent: "center", margin: " 22%" }}>
            <CircularProgress width={28} height={28} />
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={notif}
        onClose={() => setNotif(false)}
        autoHideDuration={6000}
        // message={notifMsg}
        key={vertical + horizontal}
        className="notifs"
      >
        <div>
          <span>{notifMsg} </span> TRX rides into the Jungle!
          <ClearIcon
            onClick={() => setNotif(false)}
            style={{
              color: "#009EE4",
              width: "15px",
              height: "15px",
              position: "absolute",
              top: "5",
              right: "5",
            }}
          ></ClearIcon>
        </div>
      </Snackbar>
    </div>
  );
};

export default Transactions;
