import React, { useState } from "react";
import "./style.css";

const Animated = ({ loading }) => {
  //   useEffect(() => {
  //     var wrapper = $("#button-wrapper");

  //     $(".submit").click(function () {
  //       if (wrapper.not(".checked")) {
  //         wrapper.addClass("checked");
  //         setTimeout(function () {
  //           wrapper.removeClass("checked");
  //         }, 8000);
  //       }
  //     });
  //   }, []);

  const animation = () => {
    console.log("ok");
  };
  // const [loading, set] = useState(false);

  return (
    <div id="button-wrappered" className={`${loading ? "checked" : ""}`}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        {" "}
        <defs>
          {" "}
          <filter id="goo">
            {" "}
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />{" "}
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="goo"
            />{" "}
            <feBlend in="SourceGraphic" in2="goo" />{" "}
          </filter>{" "}
        </defs>
      </svg>
      <p className="submited">Buy Ceto</p>
      <div className="loader-wrapper">
        <span className="loader yellow"></span>
        <span className="loader pink"></span>
      </div>
      <span className="loader orange"></span>

      <div className="check-wrapper">
        <svg version="1.1" width="65px" height="38px" viewBox="0 0 64.5 37.4">
          <polyline className="check" points="5,13 21.8,32.4 59.5,5 " />
        </svg>
      </div>
    </div>
  );
};

export default Animated;
