import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import Actionbar from "../components/actionbar/actionbar";
import Navbar from "../components/navbar/navbar.component";
import SubNavbar from "../components/sub-navbar/sub-navbar.component";
import Footer from "../components/footer/footer.component";
import Buysell from "../components/buyselltokens/buysell";
import Currencies from "../components/currencies/currencies";
import Reinvestdividends from "../components/reinvestdividends/reinvestdividends";
import Holdings from "../components/holdings";
import Transactions from "../components/recent-transactions/recent-transactions.component";
import Withinv from "../components/withinv/withinv";
import Buypopup from "../components/buypopup/buypopup";
import Withdrawpopup from "../components/withpopup/withpopup";
import Transferpopup from "../components/transferpopup/transferpopup";
import styles from "../styles/Marketplace.module.scss";
import web3_obj from "../getWeb3";
import LinearProgress from "@material-ui/core/LinearProgress";
import Contract_obj from "../getTronweb";
import { connect } from "react-redux";
import * as actions from "../actions";
import get_tronweb from "./tronwebConnection";
import Reinvest2 from "../components/reinvest2";
import ConnectionModal from "../components/connectionpopup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomLoader from "../components/Loader.js";
import metacoinConfig from "../metacoin-config.js";
import { Tabs } from "antd";
import WrappedBuySell from "../components/buyselltokens/wrappedBuySell";
import GreenButton from "../components/cinema-draft-button/cinema-draft-button.component";
import WrappedHoldings from "../components/reinvest2/wrappedHoldings";

import axios from "axios";
import eth from "../public/ethe.png";
import bnb from "../public/bnb.png";
import ArrowInButton from "../public/herosection-button-icon.svg";
import CinemaDraftButton from "../components/cinema-draft-button/cinema-draft-button.component";
import "../styles/banner.css";
import Banner from "../components/banner";
import CurrencyBox from "../components/CurrencyBox";
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Exchange = props => {
  const [state, setstate] = useState();
  const [contracts, setContracts] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [balance, setBalance] = useState();
  const [contractBalance, setContractBalance] = useState();
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(0);
  const [timer, setTimer] = useState();
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [position, setPosition] = useState(0);
  const [scrolling, setScrolling] = useState("up");
  const [wrappedTheme, setWrappedTheme] = useState(1);
  const [txnLoading, setTxnLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [blockchain, setBlockchain] = useState("");

  const changeState = num => {
    setstate(num);
  };
  useEffect(() => {
    if (txnLoading == true) {
      window.scrollTo(0, 0);
    }
  }, [txnLoading]);
  const setToken = async () => {
    setBlockchain("trx");
    await props.setBlockchain("trx");
  };
  const settings = () => {
    setToken();

    if (
      props.location.pathname == "buy" ||
      props.location.pathname == "exchange" ||
      props.match.params.ref_name ||
      props.location.pathname == "/exchange" ||
      props.location.pathname == "/eceto/buy" ||
      props.location.pathname == "/ceto/buy" ||
      props.location.pathname == "/bceto/buy" ||
      props.location.pathname == "/buy"
    ) {
      setstate(0);
    } else if (
      props.location.pathname == "sell" ||
      props.location.pathname == "/sell" ||
      props.location.pathname == "/eceto/sell" ||
      props.location.pathname == "/ceto/sell" ||
      props.location.pathname == "/bceto/sell" ||
      props.location.pathname == "/eceto/withdraw" ||
      props.location.pathname == "/ceto/withdraw" ||
      props.location.pathname == "/bceto/withdraw" ||
      props.location.pathname == "withdraw" ||
      props.location.pathname == "/withdraw"
    ) {
      setstate(2);
    } else if (
      props.location.pathname == "transfer" ||
      props.location.pathname == "/transfer" ||
      props.location.pathname == "/eceto/transfer" ||
      props.location.pathname == "/ceto/transfer" ||
      props.location.pathname == "/bceto/transfer"
    ) {
      setstate(3);
    } else {
      setstate(4);
    }
    console.log(props, "njnk");
    mainFunc();
  };
  useEffect(() => {
    settings();
  }, []);
  useEffect(() => {
    settings();
  }, [props.location.pathname]);
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => {
        let data = response.data;
        setCountry(data.country_name);
      })
      .catch(error => {
        setCountry("");
      });
  };
  const calculate_days = end => {
    // console.log(end, "vcjd");
    Contract_obj.contract_instace
      .getTimestampedBalanceLedger(end - 1)
      .call({
        from: Contract_obj.accounts[0],
        feeLimit: Contract_obj.feeLimit,
      })
      .then(res => {
        // console.log(res[1].toString(), "nckjn");
        var block_timestamp = parseInt(res[1].toString());
        const thirtydays = 2592000;

        var x = setInterval(function () {
          // Get today's date and time
          const currentDate = new Date();
          const now = parseInt(currentDate.getTime().toString().slice(0, -3));

          // Find the distance between now and the count down date
          var distance = thirtydays - (now - block_timestamp);

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (60 * 60 * 24));
          var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
          var minutes = Math.floor((distance % (60 * 60)) / 60);
          var seconds = Math.floor(distance % 60);

          // Display the result in the element with id="demo"
          setTimer({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          });

          // If the count down is finished, write some text
          if (distance < 0) {
            clearInterval(x);
          }
        }, 1000);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const fetchData = async () => {
    console.log("test", Contract_obj.tronWebProvider, Contract_obj.accounts[0]);
    window.tronWeb &&
      (await Contract_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : "mainnet"
      ));

    console.log("trooon", Contract_obj);
    if (Contract_obj.tronWebProvider) {
      try {
        await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.contractAddress)
          .then(result => {
            // console.log(result, "bal");
            setContractBalance(result);
          });
        await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.accounts[0])
          .then(result => {
            // console.log(result, "user balance", Contract_obj.accounts[0]);
            setBalance(result);
          });

        await Contract_obj.contract_instace
          .getCursor()
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
          })
          .then(function (res) {
            if (res) {
              // console.log(res, typeof res, "cursor");
              var start = res[0].toString();
              var end = res[1].toString();
              // console.log(start, end);
              if (start == end || end == 0) {
                setTimer(0);
              } else {
                calculate_days(end);
              }
              setLoading(false);
              setConnectModal(false);
            }
          })
          .catch(err => console.log("err"));
      } catch (error) {
        // Catch any errors for any of the above operations.

        console.error(error);
      }
    }
  };

  useEffect(() => {
    // console.log("hii", window);
    window.addEventListener("message", function (e) {
      if (e.data.message && e.data.message.action == "setNode") {
        // console.log("setNode event", e.data.message);

        if (
          window.tronWeb &&
          window.tronWeb.fullNode.host != metacoinConfig.fullHost
        ) {
          // // console.log("catch")
          setConnectionIssue(1);
          // setConnectModal(true);
          setLoading(false);
        } else {
          setUpdated(updated + 1);
          setConnectModal(false);

          fetchData();
        }
      }
    });
    document.addEventListener("readystatechange", event => {
      if (event.target.readyState == "complete") {
        // console.log(window, event, "windowww");
        fetchData();
        setTimeout(() => {
          if (
            !window.tronWeb ||
            (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
          ) {
            // console.log("not loaded");
            // setConnectModal(true);
            setLoading(false);
            setUpdated(updated + 1);
          } else if (
            window.tronWeb &&
            window.tronWeb.fullNode.host != metacoinConfig.fullHost
          ) {
            // // console.log("catch")
            setConnectionIssue(1);
            // setConnectModal(true);
            setLoading(false);
          }
        }, 3000);
      }
    });
  }, [
    Contract_obj.tronWebProvider,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
    window.tronWeb && window.tronWeb.fullNode.host,
    window.tronWeb && window.tronWeb,
    window,
  ]);

  const mainFunc = () => {
    getGeoInfo();
    window.scrollTo(0, 0);
    var lastScrollTop = 0;
    window.addEventListener(
      "scroll",
      function () {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (window.scrollY == 0) {
          setScrolling("up");
        } else {
          if (st > lastScrollTop) {
            // downscroll code
            setScrolling("down");
          } else {
            // upscroll code
            setScrolling("up");
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
    // console.log("hii", document);
    document.addEventListener("readystatechange", event => {
      // console.log(event, "event");
      if (event.target.readyState == "complete") {
        // console.log(window, event, "windowww");
        fetchData();
        setTimeout(() => {
          if (
            !window.tronWeb ||
            (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
          ) {
            // console.log("not loaded");
            // setConnectModal(true);
            setLoading(false);
          } else if (
            window.tronWeb &&
            window.tronWeb.fullNode.host != metacoinConfig.fullHost
          ) {
            // // console.log("catch")
            setConnectionIssue(1);
            // setConnectModal(true);
            setLoading(false);
          }
        }, 3000);
      }
    });
    window.addEventListener("load", async event => {
      var obj1 = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          console.log("loadeded");

          clearInterval(obj1);
          setUpdated(updated + 1);
        }
      }, 2000);
    });
    setTimeout(() => {
      if (
        !window.tronWeb ||
        (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
      ) {
        // console.log("not loaded");
        // setConnectModal(true);
        setLoading(false);
      } else if (
        window.tronWeb &&
        window.tronWeb.fullNode.host != metacoinConfig.fullHost
      ) {
        // // console.log("catch")
        setConnectionIssue(1);
        // setConnectModal(true);
        setLoading(false);
      }
    }, 3000);
    fetchData();
  };

  return loading ? (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      <CustomLoader></CustomLoader>
    </div>
  ) : (
    <div style={{ minHeight: "100vh" }}>
      <Banner {...props}></Banner>

      <Navbar homepage={false} page={2} {...props} blockchain={blockchain} />
      <SubNavbar
        {...props}
        step={1}
        changeState={changeState}
        updated={updated}
        blockchain={blockchain}
      />
      {/* {contracts ? ( */}
      <div
        className={styles.marketplaceContainer}
        style={{
          backgroundColor: blockchain == "eth" ? "#E8E8E8" : "#f1f3fc",
        }}
      >
        {txnLoading && <LinearProgress />}
        <Actionbar
          {...props}
          theme={state}
          contractBalance={contractBalance}
          balance={balance && balance}
          updated={updated}
          flag={scrolling == "up" ? 1 : 0}
          page={0}
          blockchain={blockchain}
        />
        <div className={styles.margin}></div>
        {state != 4 && (
          <div className={styles.running_low}>
            Running low on{" "}
            {blockchain == "trx"
              ? "Tron"
              : blockchain == "eth"
              ? "Ethereum"
              : "Binance"}
            ? Get more{" "}
            {blockchain == "trx"
              ? "Tron"
              : blockchain == "eth"
              ? "Ethereum"
              : "Binance"}{" "}
            <a
              target="_blank"
              href={
                country == "India"
                  ? `https://coindcx.com/insta/buy/${blockchain}`
                  : `https://changenow.io/exchange?from=usd&to=${
                      blockchain == "bnb" ? "bnbmainnet" : blockchain
                    }&fiatMode=true${
                      blockchain == "trx" && "&link_id=cb26dc20354601"
                    }`
              }
            >
              here
            </a>
          </div>
        )}
        {state === 4 ? null : (
          <div className={styles.middlediv}>
            <Buysell
              theme={state}
              contract={contracts}
              accounts={accounts && accounts}
              updated={updated}
              setUpdated={setUpdated}
              timer={timer}
              blockchain={blockchain}
              setTxnLoading={setTxnLoading}
              {...props}
            />

            {state >= 2 ? (
              <Reinvest2
                {...props}
                updated={updated}
                setUpdated={setUpdated}
                timer={timer}
                blockchain={blockchain}
                setTxnLoading={setTxnLoading}
              />
            ) : (
              <Reinvestdividends
                {...props}
                theme={state}
                contract={contracts}
                accounts={accounts && accounts}
                updated={updated}
                setUpdated={setUpdated}
                timer={timer}
                blockchain={blockchain}
                setTxnLoading={setTxnLoading}
              />
            )}
          </div>
        )}
        {state == 4 ? null : (
          <div className={styles.belowdiv}>
            <>
              <CurrencyBox
                theme={0}
                contract={contracts}
                accounts={accounts}
                balance={balance && balance}
                updated={updated}
                setUpdated={setUpdated}
                blockchain={blockchain}
                {...props}
              ></CurrencyBox>
              <Holdings
                theme={0}
                contract={contracts}
                accounts={accounts}
                balance={balance && balance}
                updated={updated}
                setUpdated={setUpdated}
                blockchain={blockchain}
                {...props}
              />

              {/* <Transactions
              {...props}
              theme={0}
              type={1}
              updated={updated}
              setUpdated={setUpdated}
            /> */}
            </>
          </div>
        )}{" "}
        <div className={styles.belowdiv}>
          <Transactions
            {...props}
            theme={0}
            state={state}
            type={state < 4 ? 1 : 0}
            updated={updated}
            setUpdated={setUpdated}
            blockchain={blockchain}
          />
        </div>
      </div>

      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
      />
      <Footer blockchain={blockchain} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Exchange);
