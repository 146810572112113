import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar/navbar.component";
import Footer from "../components/footer/footer.component";
import styles from "./../styles/whitepaper.scss";
import { Document, Page, pdfjs } from "react-pdf";
import doc from "../assets/CETOWhitepaper1.6.pdf";
import doc_spanish from "../assets/whitepaperSpanish.pdf";
import doc_hindi from "../assets/whitepaperHindi.pdf";
import doc_filipino from "../assets/whitepaperFilipino.pdf";
import doc_french from "../assets/whitepaperFrench.pdf";
import doc_arabic from "../assets/whitepaperArabic.pdf";
import mascot from "../public/Group 4069.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// Material-UI
import { IconButton } from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { ArrowBackIos } from "@material-ui/icons";
import GreenButton from "../components/cinema-draft-button/cinema-draft-button.component";
import Pagination from "@material-ui/lab/Pagination";
import { Switch } from "antd";
import "antd/dist/antd.css";
import CinemaDraftButton from "../components/cinema-draft-button/cinema-draft-button.component";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import eth from "../public/ethe.png";
import bnb from "../public/bnb.png";
import ArrowInButton from "../public/herosection-button-icon.svg";
import Banner from "../components/banner";
const Faq = props => {
  const converter = () => {
    var base58_addresses = ["", ""];
    var hex_addresses = [];
    var base58_addresses_check = [];
    base58_addresses.forEach(element => {
      var hex = window.tronWeb.address.toHex(element);
      hex = "0x" + hex.slice(2, hex.length);
      hex_addresses.push(`ambassadors_[${hex}] = true;`);
    });
    console.log(hex_addresses);
    hex_addresses.forEach(element => {
      var base = window.tronWeb.address.fromHex(element);
      base58_addresses_check.push(base);
    });
    for (var i = 0; i < base58_addresses.length; i++) {
      if (base58_addresses_check[i] == base58_addresses[i]) {
        // console.log("correctt")
      } else {
        // console.log(base58_addresses_check ,base58_addresses,i);
      }
    }
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [page, setPage] = useState(1);
  const [lang, setLang] = useState("English");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setLang("English")}>
        <p
          className="menu_item"
          style={{ color: "#012d8c", fontSize: "14px", fontWeight: "500" }}
        >
          English
        </p>
      </Menu.Item>
      <Menu.Item onClick={() => setLang("Spanish")}>
        <p
          className="menu_item"
          style={{ color: "#012d8c", fontSize: "14px", fontWeight: "500" }}
        >
          Spanish
        </p>
      </Menu.Item>
      <Menu.Item onClick={() => setLang("Hindi")}>
        <p
          className="menu_item"
          style={{ color: "#012d8c", fontSize: "14px", fontWeight: "500" }}
        >
          Hindi
        </p>
      </Menu.Item>
      <Menu.Item onClick={() => setLang("French")}>
        <p
          className="menu_item"
          style={{ color: "#012d8c", fontSize: "14px", fontWeight: "500" }}
        >
          French
        </p>
      </Menu.Item>
      <Menu.Item onClick={() => setLang("Arabic")}>
        <p
          className="menu_item"
          style={{ color: "#012d8c", fontSize: "14px", fontWeight: "500" }}
        >
          Arabic
        </p>
      </Menu.Item>
      <Menu.Item onClick={() => setLang("Filipino")}>
        <p
          className="menu_item"
          style={{ color: "#012d8c", fontSize: "14px", fontWeight: "500" }}
        >
          Filipino
        </p>
      </Menu.Item>
    </Menu>
  );
  const getColor = type => {
    if (type == 0) {
      if (props.blockchain == "trx") {
        return "#012d8c";
      } else if (props.blockchain == "bnb") {
        return "#BF9C00";
      } else {
        return "#464646";
      }
    }
  };

  return (
    <>
      <Banner {...props}></Banner>

      <Navbar page={4} blockchain={props.utils.blockchain} {...props} />
      <div
        className="social__body"
        style={{
          backgroundColor:
            props.utils.blockchain == "eth" ? "#E8E8E8" : "#f1f3fc",
        }}
      >
        <div className="heads">
          <p className="th" style={{ color: getColor(0) }}>
            Crystal Elephant Token (CETO)
          </p>
          <div className="display_flex">
            <p className="ph" style={{ color: getColor(0) }}>
              Whitepaper
            </p>

            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                style={{ color: getColor(0) }}
                onClick={e => e.preventDefault()}
              >
                {lang} <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
        <div className="midinfo">
          <div className="motto l">
            <p>Strength.</p>
            <p>Wisdom.</p>
            <p>Loyalty.</p>
            <p>
              Be the <span>Elephant</span>{" "}
            </p>
            <p>in the Room.</p>
          </div>
          <div className="doc_container">
            <Document
              file={
                lang == "English"
                  ? doc
                  : lang == "Spanish"
                  ? doc_spanish
                  : lang == "Arabic"
                  ? doc_arabic
                  : lang == "French"
                  ? doc_french
                  : lang == "Filipino"
                  ? doc_filipino
                  : doc_hindi
              }
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p className="page_num">
              <div className="pagi">
                <Pagination
                  style={{ color: "#691AAA" }}
                  count={numPages}
                  pagep={pageNumber}
                  onChange={(e, page) => {
                    setPageNumber(page);
                  }}
                  color="primary"
                />
              </div>
            </p>
            <GreenButton
              style={{
                background:
                  props.utils.blockchain == "trx"
                    ? "#009EE4"
                    : props.utils.blockchain == "eth"
                    ? "black"
                    : "#EDC201",
                color: props.utils.blockchain == "bnb" ? "black" : "#ffffff",
                margin: "auto",
              }}
              onClick={() =>
                window.open(
                  `${window.location.origin}/static/media/${
                    lang == "English"
                      ? "CETOWhitepaper1.6.db53df38"
                      : lang == "Spanish"
                      ? "whitepaperSpanish.e140c053"
                      : lang == "French"
                      ? "whitepaperFrench.7437e343"
                      : lang == "Arabic"
                      ? "whitepaperArabic.cf68c710"
                      : lang == "Hindi"
                      ? "whitepaperHindi.f0f2693e"
                      : "whitepaperFilipino.dec89533"
                  }.pdf`
                )
              }
            >
              Download Whitepaper
            </GreenButton>
          </div>
          <div className="mascot l">
            <img
              src="https://res.cloudinary.com/dgdjabqwr/image/upload/v1610803016/cd3d/Group_5256_1_eihx0s.png"
              alt="elephant"
            />
          </div>
        </div>
      </div>
      <Footer blockchain={props.utils.blockchain} />
    </>
  );
};
const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Faq);
