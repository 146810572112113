import React, { useState, useRef, useEffect } from "react";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import styles from "./dividends.module.scss";
import SocialSharing from "../SocialSharing";

const Dividends = ({ heading, value, footer, flag, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const valueRef = useRef(null);

  const toggleDrop = e => {
    setIsOpen(isOpen => !isOpen);
  };
  const completedClass = isOpen ? styles.back : "";

  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (
        document.getElementById("drop_") &&
        document.getElementById("drop_").contains(e.target)
      ) {
        // Clicked in box
        console.log("");
      } else {
        // Clicked outside the box
        setIsOpen(false);
      }
    });
  }, []);
  return (
    <div
      className={styles.dividends}
      style={{
        backgroundColor: props.blockchain == "eth" ? "#EBEBEB" : "white",
      }}
    >
      <span
        className={styles.dividends__heading}
        style={{
          color:
            props.blockchain == "eth"
              ? "#464646"
              : props.blockchain == "bnb"
              ? "#BF9C00"
              : "#002E8C",
        }}
      >
        {heading}
      </span>

      {typeof value === "string" ? (
        <div
          className={styles.dividends__value}
          style={{
            color:
              props.blockchain == "eth" || props.blockchain == "bnb"
                ? "#A53CFF"
                : "#009EE4",
          }}
        >
          {value}
        </div>
      ) : (
        <div
          className={`${styles.dividends__drop} ${completedClass}`}
          ref={valueRef}
        >
          <div className={styles.dividends__dropMainValue}>
            {value[0]}
            <ArrowDropDownIcon />
          </div>

          <div
            className={styles.dividends__dropSubValue}
            data-style-open={isOpen}
            // onMouseLeave={() => toggleDrop()}
          >
            {value.map((v, i) => (
              <span key={`tokens__${i}`} onClick={toggleDrop}>
                {v}
              </span>
            ))}
          </div>
        </div>
      )}
      <div>
        <span
          className={styles.dividends__footer}
          style={{ color: props.blockchain == "eth" ? "#000000" : "#435475" }}
        >
          <span>{footer[0]}</span>
        </span>

        <div
          className={`${styles.dividends__drop} ${completedClass}`}
          ref={valueRef}
        >
          <span
            id="top"
            className={styles.dividends__footer}
            onClick={() => toggleDrop()}
            style={{ color: props.blockchain == "eth" ? "#000000" : "#435475" }}
            id="drop_"
            // style={{ marginLeft: "15px" }}
          >
            <span>{footer[1]}</span>
            <ArrowDropDownIcon />
          </span>

          <div
            className={styles.dividends__dropSubValue}
            data-style-open={isOpen}
            style={{ top: "15px" }}
          >
            {footer.slice(1, footer.length).map((v, i) => (
              <span
                key={`tokens__${i}`}
                onClick={() => toggleDrop()}
                className={styles.dividends__footer}
              >
                {v}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dividends;
