import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import loss_img from "./../../public/loss_img.png";
import calc from "../../public/calc.png"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({theme , showInfo, setShowInfo}) {
    // console.log(showInfo,"showInfo")
  return (
    <div>
      <Dialog
        open={showInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowInfo(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ fontWeight: "600", fontFamily: "POPPINS" }}
        >
          {"Loss Calculation"}
        </DialogTitle>
        <DialogContent>
          <img src={theme ==3 ? calc : loss_img}></img>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInfo(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
