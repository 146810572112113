import styled from "styled-components";
export const Card = styled.div`
  .withinv_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 40px #7090b026;
    width: 23.43vw;
    padding-bottom: 2rem;
    border-radius: 20px;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 600px) {
      width: 100%;
      /* margin: 0 0 2rem; */
    }
    .withdiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px 20px 0 0;
      background-color: #162752;
      padding: 17px 60px 16px 60px;
      margin-bottom: 20px;
      width: 100%;
    }
    .balancediv {
      display: flex;
      justify-content: space-between;
      width: 80%;

      .balances {
        @media screen and (max-width: 600px) {
          width: 26vw;
          /* margin: 0 0 2rem; */
        }
        .tbal {
          color: #435475;
          font-size: 18px;
          font-weight: 400;
        }

        .dbal {
          color: #009ee4;
          font-size: 32px;
          font-weight: 500;
          span {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    hr {
      border-bottom: 1px solid #bac4d7;
      width: 100%;
    }

    .price {
      font-size: 28px;
      font-weight: 300;
      text-align: center;
      letter-spacing: 0px;
      color: #a1b5e0;
      opacity: 1;
      font-weight: 300;
    }
    .down {
      margin: 1rem 0;
    }
    .pl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;

      .add {
        color: #009ee4;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .bal {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        width: 17vw;
        /* margin: 0 0 2rem; */
      }
      .tbal {
        color: #435475;
        font-size: 16px;
        font-weight: 400;
      }
      .dbal {
        color: #009ee4;
        font-size: 22px;
        font-weight: 500;
        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
      .y {
        color: #7689b0;
        font-size: 14px;
        font-weight: 600;
      }
    }
    ._extended {
      flex-direction: row;
      width: auto;
      .tbal,
      .y {
        margin: auto;
      }
      .dbal {
        margin: 0 1rem;
      }
    }
    .link {
      font-size: 18px;
      color: #002e8c;
      font-weight: 600;
      text-decoration: underline;
      margin-top: 20px;
      letter-spacing: 6.48px;
    }

    .etf {
      letter-spacing: 0px;
      color: #7689b0;
      opacity: 1;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`;
