import React, { useState, useEffect } from "react";
import "../../styles/banner.css";
import metacoinConfig from "../../metacoin-config";
import axios from "axios";
const Banner = props => {
  const [timer, setTimer] = useState();
  const [contractData, setContractData] = useState();

  const TriggerCoundown = () => {
    const end_timestamp =
      contractData.countdownTimestamp !== 0
        ? contractData.countdownTimestamp + contractData.period
        : contractData.startDate;

    var x = setInterval(function () {
      // Get today's date and time
      const currentDate = new Date();
      const now = parseInt(currentDate.getTime().toString().slice(0, -3));

      // Find the distance between now and the count down date
      var distance = end_timestamp - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (60 * 60 * 24));
      var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
      var minutes = Math.floor((distance % (60 * 60)) / 60);
      var seconds = Math.floor(distance % 60);

      // Display the result in the element with id="demo"
      setTimer({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setTimer();
      }
    }, 1000);
  };
  const getBigtentData = () => {
    var config = {
      method: "get",
      url: `https://api.crystalelephant.net/api/cache/?function=getEventData&force=1&network=${metacoinConfig["mainnet"].fullHost}&contract_address=TU6JtrDsBSGeiwyC1J8Frt7euUZj95cx7v&sender_address=${metacoinConfig["mainnet"].secondaryAccount}`,
    };

    axios(config)
      .then(function (response) {
        var eventData = response.data.data;
        var data = {
          startDate: parseInt(eventData[3]) + parseInt(eventData[5]),
          period: parseInt(eventData[5]),
          countdownTimestamp: eventData[4],
          gameStarted: eventData[6],
        };

        setContractData(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (contractData) {
      const currentDate = new Date();
      const now = parseInt(currentDate.getTime().toString().slice(0, -3));

      if (
        contractData.startDate > 0 &&
        now > contractData.startDate - contractData.period
      ) {
        TriggerCoundown();
      }
    }
  }, [contractData]);
  useEffect(async () => {
    getBigtentData();
  }, []);
  return (
    <div className="banner">
      {contractData && contractData.gameStarted && timer ? (
        <span className="txt">
          {" "}
          Our{" "}
          <a
            target="_blank"
            href="https://bigtent.crystalelephant.net/"
            rel="noreferrer"
          >
            Big Tent
          </a>{" "}
          lottery game ends in{" "}
          <span className="banner-txt-2">
            {timer
              ? timer.days < 10
                ? String(timer.days).padStart(2, "0")
                : timer.days
              : "00"}{" "}
            d{" "}
            {timer
              ? timer.hours < 10
                ? String(timer.hours).padStart(2, "0")
                : timer.hours
              : "00"}{" "}
            h{" "}
            {timer
              ? timer.minutes < 10
                ? String(timer.minutes).padStart(2, "0")
                : timer.minutes
              : "00"}{" "}
            m{" "}
            {timer
              ? timer.seconds < 10
                ? String(timer.seconds).padStart(2, "0")
                : timer.seconds
              : "00"}{" "}
            s
          </span>
        </span>
      ) : contractData && contractData.gameStarted && !timer ? (
        <span className="txt">
          {" "}
          Our{" "}
          <a
            target="_blank"
            href="https://bigtent.crystalelephant.net/"
            rel="noreferrer"
          >
            Big Tent
          </a>{" "}
          lottery game ends on{" "}
          <span>
            {" "}
            {contractData &&
              new Date(
                parseInt(
                  contractData.startDate ? contractData.startDate : 23400
                ) * 1000
              )
                .toDateString()
                .slice(0, 10)}{" "}
          </span>{" "}
        </span>
      ) : (
        <span className="txt">
          {" "}
          Join our CEFA IFO public sale{" "}
          <a
            target="_blank"
            href="https://cefa.crystalelephant.net/sale"
            rel="noreferrer"
          >
            here
          </a>
        </span>
      )}
    </div>
  );
};

export default Banner;
