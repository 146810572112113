import React from "react";
import styles from "./footer.module.scss";

const Footer = props => {
  return (
    <div
      className={styles.copyright}
      style={{
        backgroundColor:
          props.blockchain == "eth"
            ? "#1E1E1E"
            : props.blockchain == "bnb"
            ? "#1E1E1E"
            : "#002E8C",
      }}
    >
      Strength. Wisdom. Loyalty. Be the Elephant in the Room.
    </div>
  );
};

export default Footer;
