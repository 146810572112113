import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";

import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import logo from "../../public/Button - Icon (1).svg";
import arrow from "../../public/Button - Icon.svg";
import WarningIcon from "@material-ui/icons/Warning";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const Buypopup = ({
  open,
  handleClose,
  ceto,
  wceto,
  buy,
  usdToSpend,
  accBalance,
  step,
  approve,
  processing,
  blockchain,
  allowance,
}) => {
  // const [open, setmodal] = useState(false);
  const [bal, setbal] = useState(true);
  const [slug, setslug] = useState("");
  const [isavailable, setavail] = useState(true);
  const [factor, setFactor] = useState(
    blockchain == "eth" ? 1e18 : blockchain == "bnb" ? 1e18 : 1e6
  );
  const handleChange = e => {
    const { name, value } = e.target;
    setslug(value);
  };

  return (
    <Card>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            <span className="custom">Let's do this.</span>

            <CloseIcon
              onClick={handleClose}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>
          <div className="midwrapper">
            <div className="lwrap"></div>
            <div className="rwrap">
              <div className="topd">
                <div className="cl">Buy Tokens</div>
              </div>
              <div className="slug-txt-input">
                <span className="slug-val">{ceto}</span>
                <div className="slug-check">CETO</div>
              </div>

              <FormHelperText
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#7689B0",
                  fontFamily: "Poppins",
                  paddingLeft: "50px",
                }}
              >
                {/* 123.03 CETO Available today at no loss */}
              </FormHelperText>
              <div className="fee">
                <div className="f1">
                  <div className="cl">Transaction Fees</div>
                  <div className="slug-check">
                    {(ceto / 10).toFixed(3)} CETO
                  </div>
                </div>

                <hr />
                <div className="f1">
                  <div className="cl">Net Tokens</div>
                  <div className="slug-check">
                    {(wceto / factor).toFixed(6)} WCETO
                  </div>
                </div>
              </div>

              <div className="cntner">
                <div className="action-div">
                  <GreenButton
                    style={{
                      background: "#ffffff",
                      color: "#691AAA",
                      border: "1px solid #691AAA",
                      marginRight: "15px",

                      width: "200px",
                    }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </GreenButton>

                  <GreenButton
                    style={{
                      background: " #691AAA",
                      color: "#ffffff",
                      width: "210px",
                    }}
                    onClick={() => {
                      step == 2 && handleClose();
                      step == 1 && parseFloat(ceto) > parseFloat(allowance)
                        ? approve()
                        : buy();
                    }}
                  >
                    {step == 1 && parseFloat(ceto) > parseFloat(allowance)
                      ? "Approve"
                      : "Confirm"}
                    {processing ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : null}
                  </GreenButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Buypopup;
