import styled from "styled-components";
export const BuysellStyle = styled.div`
  height: 100%;
  .buysell_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => (props.blockchain == "trx" ? "#002e8c" : "#000000")};
    border-radius: 20px;
    /* border-radius: 0 20px 20px 20px; */
    padding: 30px 30px 25px 30px;
    width: 50vw;
    box-shadow: 0px 0px 40px #7090b026;
    width: ${props => (props.width ? "inherit" : "70vw")};
    font-family: "Poppins", sans-serif;
    height: 100%;
    @media screen and (max-width: 1024px) {
      width: 64vw;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 1rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .ant-input {
      background-color: transparent !important;
      color: #f4f6fe !important;
      font-size: 17px !important;
      font-weight: 500 !important;
      font-family: Poppins !important;
      padding: 10px !important;
    }
    .resultText {
      .ant-input {
        /* padding: 0 40px !important; */
      }
    }
    .flex1 {
      display: flex;
      width: 100%;
    }
    .txt11 {
      text-align: left;
      letter-spacing: 0px;
      align-self: start;
      color: ${props =>
        props.blockchain == "bnb" && props.theme > 1
          ? "black"
          : props.theme <= 1
          ? "#1fd0ff"
          : "#E1D1EE"};
      opacity: 1;
      font-family: Poppins;
      font-size: 14px;
      width: 50%;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      @media screen and (max-width: 768px) {
        font-size: 10px;
      }
    }
    .right {
      margin-left: 90px;
    }
    .txt2 {
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0px;
      color: ${props =>
        props.blockchain == "bnb" && props.theme > 1 ? "black" : "#ffffff"};
      opacity: 1;
    }
    .medium {
      font-size: 14px;
      text-align: left;
      letter-spacing: 0px;
      color: ${props =>
        props.blockchain == "bnb" && props.theme > 1 ? "black" : "#ffffff"};
      opacity: 1;
      margin-bottom: 0;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
    .upper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 46px;
      align-items: center;
      width: 100%;
      .light {
        font-size: 28px;
        letter-spacing: 0px;
        color: #fff1f5;
        opacity: 1;
        font-weight: 400;
        @media screen and (max-width: 1024px) {
          font-size: 20px;
          display: none;
        }
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      .lmobile {
        display: none;
        font-size: 28px;
        letter-spacing: 0px;
        color: #fff1f5;
        opacity: 1;
        font-weight: 300;
        @media screen and (max-width: 1024px) {
          display: block;
          font-size: 20px;
        }
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      .newlight {
        font-size: 28px;
        letter-spacing: 0px;

        opacity: 1;
        font-weight: 300;
        color: #eafbf3;
      }
    }

    .convert {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      height: 76px;
      margin-bottom: 27px;
      @media screen and (max-width: 400px) {
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        margin-bottom: 150px;
      }

      .inputText {
        /* background: url("https://res.cloudinary.com/dgdjabqwr/image/upload/v1610200564/cd3d/Group_5257_xyltnd.svg")
          no-repeat scroll 15px 10px; */
        background-color: ${props =>
          props.blockchain == "trx" ? "#002062" : "#1A1A1A"};
        background-size: 35px 35px;
        border-radius: 50px;
        border-style: none;
        color: #f4f6fe !important;
        width: 50%;
        height: 60px;
        /* padding-left: 60px; */
        font-size: 17px;
        font-weight: 500;
        position: absolute;
        left: 0;
        img {
          width: 40px;
          height: 40px;
        }
        @media screen and (max-width: 1024px) {
          height: 50px;
          font-size: 14px;
        }
        @media screen and (max-width: 768px) {
          /* height: 40px; */
          font-size: 12px;
        }
        @media screen and (max-width: 400px) {
          width: 100%;
          height: 60px;
        }
      }
      .inputText:focus {
        outline: none;
      }
      .resultText {
        /* background: url("https://res.cloudinary.com/dgdjabqwr/image/upload/v1611569700/cd3d/input_icon_lrvvme.png")
          no-repeat right 15px center; */
        background-color: ${props =>
          props.blockchain == "trx" ? "#0043cb" : "#383838"};
        background-size: 35px 35px;
        color: #fff1f5;
        border-radius: 50px;
        border-style: none;
        width: 50%;
        height: 60px;
        padding-left: 45px;
        font-size: 17px;
        font-weight: 500;
        position: absolute;
        right: 0;
        img {
          width: 40px;
          height: 40px;
        }
        @media screen and (max-width: 1024px) {
          height: 50px;
          font-size: 14px;
        }
        @media screen and (max-width: 768px) {
          /* height: 40px; */
          font-size: 12px;
        }
        @media screen and (max-width: 400px) {
          /* img {
            display: none;
          }
          background: url("https://res.cloudinary.com/dgdjabqwr/image/upload/v1611569700/cd3d/input_icon_lrvvme.png")
            no-repeat scroll 15px center; */
          width: 100%;
          margin-top: 70px;
          background-color: ${props =>
            props.blockchain == "trx" ? "#0043cb" : "#383838"};
          height: 60px;
          padding: 4px 8px;
          flex-direction: row-reverse;
        }
      }
      .resultText:focus {
        outline: none;
      }
      .inputText:active {
        outline: none;
      }
      .convertbutton {
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
        background-color: ${props =>
          props.blockchain == "trx"
            ? "#8514e2"
            : props.blockchain == "eth"
            ? "#E0E0E0"
            : "#EDC201"};
        width: 76px;
        height: 76px;
        border-radius: 50%;
        left: 43%;
        left: ${props => (props.theme == 3 ? "48%" : "47%")};
        z-index: 10;
        border: none;
        outline: none;
        @media screen and (max-width: 1024px) {
          width: 60px;
          height: 60px;
        }
        /* @media screen and (max-width: 768px) {
          width: 46px;
          height: 46px;
        } */

        @media screen and (max-width: 600px) {
          left: 40%;
        }
        @media screen and (max-width: 400px) {
          transform: rotate(90deg);
          bottom: -90px;
          position: absolute;
        }
      }
    }

    .receipeintdiv {
      width: 100%;
      margin-top: 27px;
      .inputText {
        margin-top: 10px;
        background: ${props =>
          props.blockchain == "bnb" ? "#987C00" : "#4c127c"};
        border-radius: 50px;
        border-style: none;
        color: #f4f6fe;
        width: 100%;
        height: 60px;
        padding: 10px 40px;
        font-size: 17px;
        font-weight: 500;
      }
      .inputText:focus {
        outline: none;
      }
    }
  }
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }

  #button-wrapper {
    font-family: "Roboto", sans-serif;
    font-size: 21px;
    text-transform: uppercase;
    letter-spacing: 6px;
    color: white;
    width: 100%;
    height: 100px;
    position: absolute;
    top: calc(50% - 70px);
    left: 0;
    padding: 20px 0px;
    overflow: visible;
  }

  .submit,
  .loader,
  .loader-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .submit {
    z-index: 2;
    width: 325px;
    height: 95px;
    background: linear-gradient(to right, #ffb369 0%, #ff4272 100%);
    display: block;
    border-radius: 200px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    line-height: 440%;
    transition: all 0.7s ease, line-height 0s ease, color 0.4s ease,
      filter 0.2s ease;
  }

  #button-wrapper::selection,
  .submit::selection {
    background: transparent;
  }

  .submit:before {
    content: "";
    z-index: -2;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ffb369 0%, #ff4272 100%);
    display: block;
    border-radius: 200px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.8;
    transform: scale(0.9) translateY(15px);
    filter: blur(20px);
    transition: all 0.2s ease;
  }

  .submit:after {
    content: "";
    z-index: -4;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ffb369 0%, #ff4272 100%);
    display: block;
    border-radius: 200px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transform: scale(1);
  }

  .submit:hover:before {
    transform: scale(0.9) translateY(5px);
    filter: blur(15px);
  }

  .submit:active {
    line-height: 460%;
    filter: brightness(0.95);
    transition: all 0s;
  }

  .checked .submit:before {
    top: 0px;
    opacity: 0;
  }

  .checked .submit:after {
    animation: submit-after-anim 0.5s 4.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .checked {
    -webkit-filter: url("#goo");
    filter: url("#goo");
  }

  .yellow {
    background-color: #ffb369;
  }

  .pink {
    background-color: #ff4272;
  }

  .orange {
    background-color: #ff7b6e;
    transform: scale(0.2) rotate(30deg) translate(0px);
  }

  .checked .pink {
    animation: loader-anim-pink 4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  }

  .checked .yellow {
    animation: loader-anim-yellow 4s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .checked .orange {
    animation: loader-anim-orange 4s 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .loader {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    opacity: 0;
    z-index: 1;
  }

  .checked .loader {
    opacity: 1;
  }

  .checked .loader-wrapper {
    animation: wrapper-loader-anim 4.2s linear;
    width: 95px;
    height: 95px;
    transform: scale(0);
  }

  .checked .submit {
    width: 95px;
    color: rgba(255, 255, 255, 0);
    background: linear-gradient(to right, #ff7b6e 0%, #ff7b6e 100%);
    animation: submit-anim 4s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .check-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 95px;
    height: 95px;
    z-index: -1;
    opacity: 1;
  }

  .check-wrapper svg {
    margin-top: 31px;
    margin-left: 16px;
  }

  .check {
    fill: none;
    stroke: #ffffff;
    stroke-width: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    z-index: -1;
  }

  .checked .check-wrapper {
    z-index: 3;
  }

  .checked .check {
    animation: check 4s 4.4s ease forwards;
  }

  @keyframes loader-anim-pink {
    0% {
      transform: scale(0.2) rotate(0deg) translate(220px, 0px);
    }
    100% {
      transform: scale(0.2) rotate(1100deg) translate(300px, 0px);
    }
  }

  @keyframes loader-anim-yellow {
    0% {
      transform: scale(0.2) rotate(0deg) translate(300px, 0px);
    }
    100% {
      transform: scale(0.2) rotate(1100deg) translate(220px, 0px);
    }
  }

  @keyframes loader-anim-orange {
    0%,
    70% {
      transform: scale(0.2) rotate(30deg) translate(0px);
    }
    85% {
      transform: scale(0.2) rotate(30deg) translate(-300px);
    }
    100% {
      transform: scale(0.2) rotate(30deg) translate(0px);
    }
  }

  @keyframes wrapper-loader-anim {
    0% {
      transform: scale(0) translateY(0px);
    }
    10% {
      transform: scale(1) translateY(20px);
    }
    50% {
      transform: scale(1.2) translateY(10px);
    }
    90% {
      transform: scale(1) translateY(-10px);
    }
    100% {
      transform: scale(0) translateY(0px);
    }
  }

  @keyframes submit-after-anim {
    0% {
      transform: scale(1);
      opacity: 0.3;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @keyframes submit-anim {
    0% {
      transform: scale(1);
    }
    5%,
    95% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes check {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  .Block {
    position: relative;
    width: 100%;
    margin-top: 45px;
  }
  .ant-progress-bg {
    height: 130px !important;
    border-radius: 15px !important;
  }
  .ant-progress-line {
    /* position: absolute; */
  }
  .ant-progress-outer {
    margin: 0 !important;
    padding: 0 !important;
  }
  .ant-progress-text {
    display: none;
  }
  .ant-progress-inner {
    background-color: ${props =>
      props.blockchain == "bnb" ? "#d1ac06" : "#62189f"};
    border-radius: 15px;
  }
  .progress-block {
    display: flex;
    background-color: transparent;
    border-radius: 15px;
    position: absolute;
    top: 0;
    width: 100%;

    justify-content: space-around;
    .block {
      margin: 23px 0;
      width: 25%;
    }
    .block:not(:last-child) {
      border-right: ${props =>
        props.blockchain == "bnb"
          ? "1px solid #0000003d"
          : "1px solid #61708e"};
    }
    .txt1 {
      text-align: center;
      font-size: 18px;
      /* font-weight: 600; */
      letter-spacing: 0px;
      color: ${props => (props.blockchain == "bnb" ? "white" : "#c6b1d8")};
      mix-blend-mode: ${props =>
        props.blockchain == "bnb" ? "normal" : "exclusion"};

      opacity: 0.8;
    }
    .txt2 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0px;
      color: white;
      mix-blend-mode: ${props =>
        props.blockchain == "bnb" ? "normal" : "difference"};

      opacity: 1;
      span {
        font-weight: 500;
        font-size: 15px;
      }
    }
    .txt3 {
      text-align: center;
      font-size: 18px;
      /* font-weight: 600; */

      letter-spacing: 0px;

      color: ${props => (props.blockchain == "bnb" ? "white" : "#c6b1d8")};
      mix-blend-mode: ${props =>
        props.blockchain == "bnb" ? "normal" : "exclusion"};
      opacity: 0.8;
    }
  }

  .progress-block1 {
    display: -webkit-box;
    background-color: transparent;
    border-radius: 15px;
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
    margin: auto;
    .block {
      margin: 23px 0 23px 3vw;
      padding-right: 3vw;
    }
    .block:not(:last-child) {
      border-right: 1px solid #61708e;
    }
    .txt1 {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #61708e;
      opacity: 1;
    }
    .txt2 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #081123;
      opacity: 1;
      span {
        font-weight: 500;
        font-size: 15px;
      }
    }
    .txt3 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;

      letter-spacing: 0px;
      color: #61708e;
      opacity: 1;
    }
  }
  .btm {
    display: flex;

    width: 100%;

    justify-content: space-around;
    margin: 20px 0;
    .chip {
      border-radius: 50px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: ${props => (props.blockchain == "bnb" ? "#ffffffcc" : "#081123")};
      opacity: 1;
      padding: 6px 24px;
    }
    .chip:nth-child(1) {
      background: ${props =>
        props.blockchain == "bnb"
          ? "transparent linear-gradient(90deg, #002942 0%, #0050A0 100%) 0% 0% no-repeat padding-box"
          : "transparent linear-gradient(90deg, #aacbfa 0%, #9cd2e2 100%) 0% 0% no-repeat padding-box"};
    }
    .chip:nth-child(2) {
      background: ${props =>
        props.blockchain == "bnb"
          ? "transparent linear-gradient(90deg, #0050A0 0%, #5D419D 100%) 0% 0% no-repeat padding-box"
          : "transparent linear-gradient(90deg, #94d8d3 0%, #92e3c1 100%) 0% 0% no-repeat padding-box"};
    }
    .chip:nth-child(3) {
      background: ${props =>
        props.blockchain == "bnb"
          ? "transparent linear-gradient(90deg, #5D419D 0%, #8D39A1 100%) 0% 0% no-repeat padding-box"
          : "transparent linear-gradient(90deg, #94ebb9 0%, #abc5ad 100%) 0% 0% no-repeat padding-box"};
    }
    .chip:nth-child(4) {
      background: ${props =>
        props.blockchain == "bnb"
          ? "transparent linear-gradient(90deg, #8D39A1 0%, #D028A7 100%) 0% 0% no-repeat padding-box"
          : "transparent linear-gradient(90deg, #bfef9c 0%, #ffe384 100%) 0% 0% no-repeat padding-box"};
    }
  }
  .newtheme {
    background-color: ${props =>
      props.blockchain == "bnb" ? "#DDB400" : "#691aaa"};
    .upper {
      .light {
        color: #eafbf3;
      }
    }
    .convert {
      .ant-input {
        color: ${props =>
          props.blockchain == "bnb" ? "black !important" : "white"};
      }
      .inputText {
        color: black;
        background: ${props =>
          props.blockchain == "bnb" ? "#987C00" : "#4c127c"};
      }
      .resultText {
        background-color: ${props =>
          props.blockchain == "bnb" ? "#FFD000" : "#7e1cce"};
        @media screen and (max-width: 400px) {
          /* img {
            display: none;
          }
          background: url("https://res.cloudinary.com/dgdjabqwr/image/upload/v1610200564/cd3d/Group_5257_xyltnd.svg")
            no-repeat scroll 15px center; */
          background-color: #7e1cce;
          padding: 4px 8px;
          flex-direction: row-reverse;
        }
      }
      .convertbutton {
        background: #009ee4;
      }
    }
  }
  .ss {
    @media screen and (max-width: 400px) {
      display: none !important;
    }
  }
`;
