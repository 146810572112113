import styled from "styled-components";
export const Card = styled.div`
  .modal-content {
    position: relative;
    padding: 30px 40px 40px 60px;
    width: 59vw;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 600px) {
      width: 90vw;
      padding: 30px 1rem;
    }
    .upperdiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 60px;
      font-size:32px;
      color: #691AAA;
      font-weight:400;
      .custom {
        color: #691aaa;
        font-size: 32px;
        font-weight: 300;
      }
    }

    .ref-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
      margin-bottom: 90px;
      flex-wrap: wrap;
      .domain {
        color: #7689b0;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 30px;
        margin-right: 17px;
        @media screen and (max-width: 600px) {
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }

      .slug {
        display: flex;
        flex-direction: column;
        flex: 1;
        .MuiOutlinedInput-root {
          font-family: "Poppins", sans-serif;
        }

        .slug-check {
          display: flex;
          align-items: center;
          min-width: 130px;
          .is-available {
            color: #7689b0;
            opacity: 1;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .MuiInputBase-input {
          color: #002e8c !important;
          font-size: 24px !important;
          font-weight: 500 !important;
          padding: 20px 40px;
        }
        .cl {
          color: #002e8c;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 12px;
        }
      }
    }
    .cl {
      color: #002e8c;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .action-div {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }

    .middiv {
      display: flex;
      flex-direction: column;
      align-items: center;

      .warn {
        color: #162752;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        margin: 30px 0 15px;
      }

      .inf {
        color: #435475;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        width: 65%;
        margin-bottom: 50px;
      }
    }
  }
  .modal-close {
    position: absolute;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 50px;
  }
  .infor {
    display: flex;
    justify-content: center;
    .box-1 {
      padding: 0 2rem;
      width:35%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    hr {
      transform: rotate(90deg);
    }
    .box-1:not(:last-child) {
      border-right: 1px solid grey;
    }
    .txt1 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #009ee4;
      opacity: 1;
      text-align: right;
    }
    .txt2 {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #162752;
      opacity: 1;
      text-align: right;
    }
    .lft {
      text-align: left;
    }
  }
`;
