import React, { useEffect, useState } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";

import { ReferStyle } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import custom_link from "../../public/Path 7860.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import ReferralModal from "../../components/referralpopup";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import metacoinConfig from "../../metacoin-config.js";
import ConnectionModal from "../connectionpopup";
import web3_obj from "../../getWeb3";
const Refer = ({
  theme,
  customize_link,
  contract,
  updated,
  setUpdated,
  txnLoading,
  setTxnLoading,
  ...props
}) => {
  const [openModal, setOpen] = useState(false);
  const [custom, setCustom] = useState();
  const [ref_address, setRefAddress] = useState(contract.accounts[0]);
  const [copied, setCopied] = useState(false);
  const [balance, setBalance] = useState();
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const hexToString = hex => {
    var string = "";
    for (var i = 0; i < hex.length; i += 2) {
      if (hex.substr(i, 2) == "00") {
        break;
      } else if (hex.substr(i, 2) == "0x") {
        continue;
      } else {
        string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
      }
    }

    string = string.trim();
    console.log(string.length, string, "pp");
    setCustom(string);

    return string;
  };
  const fetchData = async () => {
    console.log("fetching...");
    // await contract.init()
    if (contract.tronWebProvider) {
      console.log("fetchingg...");

      var obj = setInterval(async () => {
        setRefAddress(contract.accounts[0]);

        contract.contract_instace &&
          contract.contract_instace
            .getReferralNameForAddress(contract.accounts[0])
            .call({
              from: contract.accounts[0],
              feeLimit: contract.feeLimit,
            })
            .then(function (res) {
              if (res) {
                clearInterval(obj);

                console.log(res.toString(), "ref name");
                res.toString() !=
                  "0x0000000000000000000000000000000000000000000000000000000000000000" &&
                  hexToString(res.toString());
              }
            });
      }, 2);
    }
  };
  const ethData = async () => {
    console.log("fetching...");
    // await contract.init()
    if (
      web3_obj &&
      web3_obj.accounts[0][0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 1 || web3_obj.networkId == 3)
    ) {
      setRefAddress(web3_obj.accounts[0][0]);
      var referral_name = await web3_obj.contract_instace.methods
        .getReferralNameForAddress(web3_obj.accounts[0][0])
        .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });

      referral_name.toString() !=
        "0x0000000000000000000000000000000000000000000000000000000000000000" &&
        hexToString(referral_name.toString());
    }
  };
  const bnbData = async () => {
    console.log("fetching...");
    // await contract.init()
    if (
      web3_obj &&
      web3_obj.accounts[0][0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 97 || web3_obj.networkId == 56)
    ) {
      setRefAddress(web3_obj.accounts[0][0]);
      var referral_name = await web3_obj.contract_instace.methods
        .getReferralNameForAddress(web3_obj.accounts[0][0])
        .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });

      referral_name.toString() !=
        "0x0000000000000000000000000000000000000000000000000000000000000000" &&
        hexToString(referral_name.toString());
    }
  };
  useEffect(() => {
    props.utils.blockchain == "eth"
      ? ethData()
      : props.utils.blockchain == "bnb"
      ? bnbData()
      : fetchData();
  }, [contract, updated, props.utils.blockchain]);
  useEffect(() => {
    if (props.utils.blockchain == "eth") {
      ethData();
    } else if (props.utils.blockchain == "bnb") {
      bnbData();
    } else {
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          console.log("setNode eventt", e.data.message);

          if (
            window.tronWeb &&
            window.tronWeb.fullNode.host == metacoinConfig.fullHost
          ) {
            fetchData();
          }
        }
      });
    }
  }, []);
  const chechConnection = () => {
    if (props.utils.blockchain == "eth") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![1, 3].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        setOpen(true);
      }
    } else if (props.utils.blockchain == "bnb") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![56, 97].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        setOpen(true);
      }
    } else {
      if (
        !window.tronWeb ||
        (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
      ) {
        console.log("not loaded");
        setConnectModal(true);
      } else if (
        window.tronWeb.fullNode.host != metacoinConfig["mainnet"].fullHost &&
        window.tronWeb.fullNode.host != metacoinConfig["shasta"].fullHost
      ) {
        // console.log("catch")
        setConnectionIssue(1);
        setConnectModal(true);
      } else {
        setOpen(true);
      }
    }
  };
  return (
    <>
      <ReferStyle theme={theme} blockchain={props.blockchain}>
        <div className="buysell_container">
          <div className="upper">Summon the Herd </div>
          <div
            className="medtext"
            style={{
              color: props.blockchain == "trx" ? "#b1bfdd" : "#ffffffd6",
            }}
          >
            <p> Safety in numbers: It’s a jungle out there.</p>
            <p>
              {" "}
              Share your referral link with others to earn 1/3 of the dividends
              of those who sign up using it.
            </p>
          </div>
          <div className="convert">
            <p className="k">Referral Link</p>
            <input
              value={custom ? custom : ref_address}
              className={`resultText`}
              type="text"
            />
            <div className="reflinks">
              <button className="eachlink" onClick={() => chechConnection()}>
                <img src={custom_link} alt="" />
                <span>Customize Link</span>
              </button>

              <CopyToClipboard
                text={`${window.location.origin}/${
                  props.utils.blockchain == "bnb"
                    ? "bceto"
                    : props.utils.blockchain == "eth"
                    ? "eceto"
                    : "ceto"
                }-ref/${custom ? custom : ref_address}`}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => setCopied(false), 2000);
                }}
              >
                <button className="eachlink">
                  <img
                    src="https://res.cloudinary.com/dgdjabqwr/image/upload/v1609915802/cd3d/Icon_feather-link_1_nbiotj.png"
                    alt=""
                  />
                  <span>Copy Link</span>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        {copied && <div className="snackbar">Copied</div>}
      </ReferStyle>

      <ReferralModal
        openModal={openModal}
        setOpen={setOpen}
        ref_address={ref_address}
        customize_link={customize_link}
        balance={balance && balance}
        contract={contract}
        custom={custom}
        setUpdated={setUpdated}
        updated={updated}
        txnLoading={txnLoading}
        setTxnLoading={setTxnLoading}
        {...props}
      ></ReferralModal>
      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
        setConnectModal={setConnectModal}
      />
    </>
  );
};

const mapStateToProps = state => {
  console.log(state, "state");
  return {
    currencies: state.utils.currencies,
  };
};

export default connect(mapStateToProps, actions)(Refer);
