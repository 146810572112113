import styled from "styled-components";

import { Button } from "@material-ui/core";

export const StyledCinemaDraftButton = styled(Button)`
  &.MuiButton-root {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 50px;
    padding: 1em 2em;
    background: #1d9756;
    font-family: Poppins;
    font-weight: 600;
    letter-spacing: 5px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 10px 10px 20px #00000040;
    height: 60px;

    &:hover {
      background: #1d9756;
    }

    img {
      margin-left: 10px;
    }

    @media screen and (max-width: 1600px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1024px) {
      font-size: ${props => (props.actionbar ? "10px" : "12px")};
      height: ${props => (props.actionbar ? "36px" : "50px")};
    }
  }
`;
