import styled from "styled-components";
export const ReferStyle = styled.div`
  .snackbar {
    background-color: white !important;
    color: #080711ba;
    position: fixed;
    top: 20px;
    left: 45%;
    letter-spacing: normal;
    padding: 10px 38px;
    font-family: "Poppins";
    font-weight: 500;
    border-radius: 4px;
    text-transform: initial;
    z-index: 1000;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  .buysell_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #002e8c;
    background: ${props =>
      props.blockchain == "trx"
        ? "#002e8c"
        : props.blockchain == "bnb"
        ? "#1E1E1E"
        : "#1E1E1E"};
    border-radius: 20px;
    padding: 60px 30px 57px 30px;
    width: 68vw;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
    }
    .medium {
      font-size: 18px;

      letter-spacing: 0px;
      color: #fff1f5;
      opacity: 1;
      font-weight: 500;
    }
    .upper {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      align-items: center;
      width: 100%;
      color: #ffffff;
      font-weight: 300;
      font-size: 32px;
      text-align: center;
      @media screen and (max-width: 600px) {
        font-size: 25px;
      }
    }
    .medtext {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #b1bfdd;
      margin-bottom: 50px;
      @media screen and (max-width: 600px) {
        font-size: 13px;
      }
      p {
        text-align: center;
      }
    }

    .convert {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      margin-bottom: 27px;
      .k {
        font-size: 18px;
        margin-bottom: 10px;
        color: #fff1f5;
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }

      .resultText {
        display: flex;
        align-items: center;
        justify-content: center;

        background: ${props =>
          props.blockchain == "trx"
            ? "#002062"
            : props.blockchain == "bnb"
            ? "#383838"
            : "#383838"};
        color: #fff1f5;
        border-radius: 50px;
        border-style: none;
        width: 50%;
        height: 60px;
        padding: 0 20px;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        font-family: "Poppins", sans-serif;
        @media screen and (max-width: 600px) {
          width: 100%;
          padding: 0 1rem;
        }
      }
      .resultText:focus {
        outline: none;
      }
      .reflinks {
        display: flex;
        justify-content: center;
        width: 50%;
        margin-top: 15px;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        .eachlink {
          :not(:last-child) {
            margin-right: 30px;
          }
          span {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0px;
            color: #fff1f5;
            opacity: 1;
            margin-left: 10px;
            @media screen and (max-width: 600px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
`;
