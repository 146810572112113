var wrappedConfig = {
    //--------MAINNET--------
  
    mainnet: {
      contractAddress: "41be2382e25b7f1a9e58ecca2eed10a05b2071ec4f",
      contractAddressBase: "TTJZvsaSitbyr5YSTSgK5d1m9qJSUUbP76",
      privateKey:
        "CBB6FDC134599AD3FF5D8E4A23E87EB1289BC6985A3380D8FAA35CDBA677DA05",
      fullHost: "https://api.shasta.trongrid.io",
      url : "https://shasta.tronscan.org"
    },

    //---------SHASTA----------

    shasta: {
      contractAddress: "41be2382e25b7f1a9e58ecca2eed10a05b2071ec4f",
      contractAddressBase: "TTJZvsaSitbyr5YSTSgK5d1m9qJSUUbP76",
      privateKey:
        "CBB6FDC134599AD3FF5D8E4A23E87EB1289BC6985A3380D8FAA35CDBA677DA05",
      fullHost: "https://api.shasta.trongrid.io",
      url : "https://shasta.tronscan.org"
  
    },
  };
  
  export default wrappedConfig;
  