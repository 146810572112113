import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import Actionbar from "../components/actionbar/actionbar";
import Navbar from "../components/navbar/navbar.component";
import SubNavbar from "../components/sub-navbar/sub-navbar.component";
import Footer from "../components/footer/footer.component";
import Buysell from "../components/buyselltokens/buysell";
import Currencies from "../components/currencies/currencies";
import Reinvestdividends from "../components/reinvestdividends/reinvestdividends";
import Holdings from "../components/holdings/holdings.component";
import Transactions from "../components/recent-transactions/recent-transactions.component";
import Withinv from "../components/withinv/withinv";
import Buypopup from "../components/buypopup/buypopup";
import Withdrawpopup from "../components/withpopup/withpopup";
import Transferpopup from "../components/transferpopup/transferpopup";
import styles from "../styles/Marketplace.module.scss";
import ceto_eth from "../getWeb3";
import Contract_obj from "../getTronweb";
import { connect } from "react-redux";
import * as actions from "../actions";
import get_tronweb from "./tronwebConnection";
import Reinvest2 from "../components/reinvest2";
import ConnectionModal from "../components/connectionpopup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomLoader from "../components/Loader.js";
import metacoinConfig from "../metacoin-config.js";
import { Tabs } from "antd";
import WrappedBuySell from "../components/buyselltokens/wrappedBuySell";
import GreenButton from "../components/cinema-draft-button/cinema-draft-button.component";
import WrappedHoldings from "../components/reinvest2/wrappedHoldings";
import Vault_obj from "../getWrappedContract";
import Vault_eth from "../getWrappeddContractWeb3";
import LinearProgress from '@material-ui/core/LinearProgress';

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Wrapped = props => {
  const [state, setstate] = useState(0);
  const [contracts, setContracts] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [balance, setBalance] = useState();
  const [contractBalance, setContractBalance] = useState();
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(0);
  const [timer, setTimer] = useState();
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [position, setPosition] = useState(0);
  const [scrolling, setScrolling] = useState("up");
  const [wrappedTheme, setWrappedTheme] = useState(1);
  const [txnLoading , setTxnLoading] = useState(false)

  const changeState = num => {
    setstate(num);
  };
  useEffect(() => {
    if (txnLoading == true) {
      window.scrollTo(0, 0);
    }
  }, [txnLoading]);
  const fetchData = async () => {
    console.log("test", Contract_obj.tronWebProvider, Contract_obj.accounts[0]);

    window.tronWeb &&
      (await Contract_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : "mainnet"
      ));
    window.tronWeb &&
      (await Vault_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : "mainnet"
      ));
    console.log("trooon", Contract_obj);
    if (Contract_obj.tronWebProvider) {
      try {
        await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.contractAddress)
          .then(result => {
            console.log(result, "bal");
            setContractBalance(result);
          });
        await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.accounts[0])
          .then(result => {
            console.log(result, "user balance", Contract_obj.accounts[0]);
            setBalance(result);
          });
      } catch (error) {
        // Catch any errors for any of the above operations.

        console.error(error);
      }
    }
  };

  useEffect(() => {
    console.log("hii", window);
    window.addEventListener("message", function (e) {
      if (e.data.message && e.data.message.action == "setNode") {
        console.log("setNode event", e.data.message);

        if (
          window.tronWeb &&
          window.tronWeb.fullNode.host != metacoinConfig.fullHost
        ) {
          // console.log("catch")
          setConnectionIssue(1);
          // setConnectModal(true);
          setLoading(false);
        } else {
          setUpdated(updated + 1);
          setConnectModal(false);

          fetchData();
        }
      }
    });
    document.addEventListener("readystatechange", event => {
      if (event.target.readyState == "complete") {
        console.log(window, event, "windowww");
        fetchData();
        setTimeout(() => {
          if (
            !window.tronWeb ||
            (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
          ) {
            console.log("not loaded");
            // setConnectModal(true);
            setLoading(false);
            setUpdated(updated + 1);
          } else if (
            window.tronWeb &&
            window.tronWeb.fullNode.host != metacoinConfig.fullHost
          ) {
            // console.log("catch")
            setConnectionIssue(1);
            // setConnectModal(true);
            setLoading(false);
          }
        }, 3000);
      }
    });
  }, [
    Contract_obj.tronWebProvider,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
    window.tronWeb && window.tronWeb.fullNode.host,
    window.tronWeb && window.tronWeb,
    window,
  ]);

  useEffect(async () => {
    window.tronWeb &&
      (await Contract_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : "mainnet"
      ));
    window.tronWeb &&
      (await Vault_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : "mainnet"
      ));
    window.scrollTo(0, 0);
    var lastScrollTop = 0;
    window.addEventListener(
      "scroll",
      function () {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (window.scrollY == 0) {
          setScrolling("up");
        } else {
          if (st > lastScrollTop) {
            // downscroll code
            setScrolling("down");
          } else {
            // upscroll code
            setScrolling("up");
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
    console.log("hii", document);
    if (props.utils.blockchain == "trx") {
      document.addEventListener("readystatechange", event => {
        console.log(event, "event");
        if (event.target.readyState == "complete") {
          console.log(window, event, "windowww");
          fetchData();
          setTimeout(() => {
            if (
              !window.tronWeb ||
              (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
            ) {
              console.log("not loaded");
              // setConnectModal(true);
              setLoading(false);
            } else if (
              window.tronWeb &&
              window.tronWeb.fullNode.host != metacoinConfig.fullHost
            ) {
              // console.log("catch")
              setConnectionIssue(1);
              // setConnectModal(true);
              setLoading(false);
            }
          }, 3000);
        }
      });
      setTimeout(() => {
        if (
          !window.tronWeb ||
          (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
        ) {
          console.log("not loaded");
          // setConnectModal(true);
          setLoading(false);
        } else if (
          window.tronWeb &&
          window.tronWeb.fullNode.host != metacoinConfig.fullHost
        ) {
          // console.log("catch")
          setConnectionIssue(1);
          // setConnectModal(true);
          setLoading(false);
        }
      }, 3000);
      fetchData();
    } else {
      await Vault_eth.init();
      await ceto_eth.init();
      setLoading(false);

    }
  }, []);

  return loading ? (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      <CustomLoader></CustomLoader>
    </div>
  ) : (
    <div style={{ minHeight: "100vh" }}>
      <Navbar homepage={false} page={3} {...props} />
      <SubNavbar
        {...props}
        step={1}
        changeState={changeState}
        updated={updated}
      />
      {/* {contracts ? ( */}
      <div className={styles.marketplaceContainer}>
      {txnLoading && <LinearProgress />}

        <Holdings
          theme={0}
          contract={contracts}
          accounts={accounts}
          balance={balance && balance}
          updated={updated}
          setUpdated={setUpdated}
          {...props}
        />
        {state === 4 ? null : (
          <div className={styles.section101}>
            <div>
              <div className={styles.btnss}>
                <div
                  className={wrappedTheme == 1 ? styles.btn1 : styles.bTn}
                  style={{ zIndex: "3" }}
                >
                  <div
                    className={styles.pseudo}
                    style={{ display: wrappedTheme == 2 && "none" }}
                  ></div>
                  <GreenButton
                    style={{
                      zIndex: "2",
                      marginBottom: "6px",
                      background: "#002E8C",
                      color: "#ffffff",
                      boxShadow: wrappedTheme == 1 && "none",
                    }}
                    // onClick={() => history.replace({ pathname: "/buy" })}
                    actionbar={true}
                    onClick={() => {
                      setWrappedTheme(1);
                      setUpdated(updated + 1);
                    }}
                  >
                    BUY WCETO
                  </GreenButton>
                </div>
                <div
                  className={wrappedTheme == 2 ? styles.btn2 : styles.bTn}
                  style={{ zIndex: wrappedTheme == 1 && "3" }}
                >
                  <div
                    className={styles.pseudo}
                    style={{ display: wrappedTheme == 1 && "none" }}
                  ></div>

                  <GreenButton
                    style={{
                      marginBottom: "6px",
                      background: "#691AAA",
                      boxShadow: wrappedTheme == 2 && "none",
                    }}
                    onClick={() => {
                      setWrappedTheme(2);
                      setUpdated(updated + 1);
                    }}
                    // onClick={() => history.replace({ pathname: "/sell" })}
                    actionbar={true}
                  >
                    SELL WCETO
                  </GreenButton>
                </div>
              </div>

              <div className={styles.section102}>
                <WrappedBuySell
                  theme={wrappedTheme}
                  contract={contracts}
                  accounts={accounts && accounts}
                  updated={updated}
                  setUpdated={setUpdated}
                  timer={timer}
                  txnLoading = {txnLoading}
                  setTxnLoading = {setTxnLoading}
                  {...props}
                />
              </div>
            </div>

            <WrappedHoldings updated={updated}></WrappedHoldings>
          </div>
        )}
        <div className={styles.belowdiv}>
          {state === 0 ? (
            <>
              <Transactions
                {...props}
                theme={0}
                type={1}
                updated={updated}
                setUpdated={setUpdated}
              />
            </>
          ) : (
            <Transactions
              {...props}
              state={state}
              updated={updated}
              setUpdated={setUpdated}
            />
          )}
        </div>
      </div>

      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
      />
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Wrapped);
