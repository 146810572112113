import React, { useState, useRef, useEffect } from "react";
import styles from "./dropdown.module.scss";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
const Dropdownn = ({ heading, value, footer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const valueRef = useRef(null);

  const toggleDrop = e => {
    setIsOpen(isOpen => !isOpen);
  };
  useEffect(() => {
    setIsOpen(false);
    window.addEventListener("click", function (e) {
      if (
        document.getElementById("drop") &&
        document.getElementById("drop").contains(e.target)
      ) {
        // Clicked in box
        // toggleDrop()
        console.log("");
      } else {
        // Clicked outside the box
        setIsOpen(false);
      }
    });
  }, []);
  return (
    <div className={styles.dividends__drop} ref={valueRef}>
      <div
        className={`${styles.dividends__dropMainValue} ${styles.reduced}`}
        onClick={() => toggleDrop()}
        id="drop"
      >
        {value[0]}
        <ArrowDropDownIcon />
      </div>
      {isOpen && (
        <div
          className={styles.dividends__dropSubValue}
          data-style-open={isOpen}
        >
          {value.map((v, i) => (
            <span key={`tokens__${i}`} onClick={toggleDrop}>
              {v}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdownn;
