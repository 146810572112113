import {
  USER_LOGGEDIN,
  PROFILE_SET,
  LOGOUT_USER,
  CURRENCIES,
  SET_ENV,
  BLOCKCHAIN,
  STATS,
} from "./types";
import browserHistory from "../history";
import axios from "axios";

export function login(userData) {
  const url = "/user/login/";
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    return api
      .post(url, userData)
      .then(response => {
        console.log(response);
        if (
          response.status === 200 &&
          response.data &&
          response.data.data &&
          response.data.data.user_id
        ) {
          setAuthorizationToken(response.data.data);
          dispatch({
            type: USER_LOGGEDIN,
            payload: response.data.data,
          });
          // browserhistory.replace("/dashboard");
          return;
          //history.replace("dashboard");
        }
        if (response.error) {
          alert(response.error);
        } else {
          alert("Something went wrong!");
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function logout(userId) {
  const url = `/user/${userId}/logout/`;
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    return api
      .post(url, {})
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          setAuthorizationToken();
          dispatch({
            type: LOGOUT_USER,
          });
          // browserhistory.replace("/");
          //history.replace("dashboard");
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function logoutWithoutUserid() {
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    setAuthorizationToken();
    dispatch({
      type: LOGOUT_USER,
    });
    // browserhistory.replace("/");
  };
}

export function fetchProfile(userId) {
  const url = `/user/${userId}/profile/`;
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    return api
      .get(url)
      .then(response => {
        console.log(response);
        if (response.status === 200 && response.data && response.data.data) {
          dispatch({
            type: PROFILE_SET,
            payload: response.data.data,
          });
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function setCurrencies(fsym) {
  console.log(fsym, "fsym");
  var config = {
    method: "get",
    url: `https://api.crystalelephant.net/api/currency/rates?currency=${fsym.toUpperCase()}`,
    headers: {},
  };
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    return axios(config)
      .then(function (response) {
        var data = response.data;
        console.log(data, "usd");
        dispatch({
          type: CURRENCIES,
          payload: data,
        });
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function setEnv(instance, accounts, contract_balance) {
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    return dispatch({
      type: SET_ENV,
      payload: { instance, accounts, contract_balance },
    });
  };
}

export function setBlockchain(data) {
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    dispatch({
      type: BLOCKCHAIN,
      payload: data,
    });
  };
}

export function setStats(data) {
  return (dispatch, getState, { api, setAuthorizationToken }) => {
    dispatch({
      type: STATS,
      payload: data,
    });
  };
}
