import React, { useEffect, useState } from "react";
import styles from "./reinvestdividends.module.scss";
import Dropdown from "../dropdown/dropdown.component";
import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import arrow from "../../public/Button - Icon.svg";
import dividends_img from "../../public/Dividends - Icon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Contract_obj from "../../getTronweb";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Update } from "@material-ui/icons";
import Reinvestpopup from "../reinvestpopup";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutoReinvestModal from "../autoreinvest";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import metacoinConfig from "../../metacoin-config.js";
import ConnectionModal from "../connectionpopup";
import web3_obj from "../../getWeb3";
import axiosInstance from "../../config/axios";

import Info from "../infoBox/infobox";
const Reinvestdividends = ({
  theme,
  contract,
  accounts,
  updated,
  setUpdated,
  timer,
  txnLoading,
  setTxnLoading,
  ...props
}) => {
  const [checked, setChecked] = useState(false);
  const [dividends, setDividends] = useState();
  const [tokens, setTokens] = useState();
  const [open, setOpen] = useState(false);
  const [eth, setEth] = useState();
  const [autoReinvest, setAutoReinvest] = useState();
  const [processing, setProcessing] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [period, setPeriod] = useState(86400);
  const [minimumDividendValue, setMinimumDividendValue] = useState(
    props.utils.blockchain == "trx"
      ? 50
      : props.utils.blockchain == "eth"
      ? 0.25
      : 0.03
  );
  const [rewardPerInvocation, setRewardPerInvocation] = useState(
    props.utils.blockchain == "trx"
      ? 20
      : props.utils.blockchain == "eth"
      ? 0.025
      : 0.003
  );
  const [show_auto_reinvest_popup, set_show_auto_reinvest_popup] = useState(
    false
  );
  const [autoReinvestModal, setAutoReinvestModal] = useState(false);
  const [autoReinvestEntry, setAutoReinvestEntry] = useState();
  const [accBalance, setAccBalance] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const fetchData = async force => {
    setProcessing(false);
    console.log("fetching");
    axiosInstance
      .get(
        `/api/cache/?function=getAutoReinvestEntry&expiry=30&force=${force}&network=${
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : "mainnet"
        }&contract_address=${Contract_obj.contractAddress}&sender_address=${
          Contract_obj.accounts[0]
        }`,
        {
          timeout: 50000,
        }
      )
      .then(resp => {
        console.log(resp.data.data, "setAutoReinvest");
        var res = resp.data.data;
        if (res[0] == 0) {
          setAutoReinvest(false);
          setChecked(false);
        } else {
          setAutoReinvest(true);
          setChecked(true);
          setAutoReinvestEntry(res);
          setPeriod(parseInt(res[2].toString()));

          setRewardPerInvocation(parseInt(res[1].toString()) / factor);
          setMinimumDividendValue(parseInt(res[3].toString()) / factor);
        }
        // setTokens(res.toString());
      })
      .catch(err => {
        console.log(err);
      });

    axiosInstance
      .get(
        `/api/cache/?function=myDividends&arguments=True&expiry=30&force=${force}&network=${
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : "mainnet"
        }&contract_address=${Contract_obj.contractAddress}&sender_address=${
          Contract_obj.accounts[0]
        }`,
        {
          timeout: 50000,
        }
      )
      .then(resp => {
        console.log(resp.data.data, "setDividends");

        setDividends(parseInt(resp.data.data.toString()) / factor);
      })
      .catch(err => {
        console.log(err);
      });
    axiosInstance
      .get(
        `/api/cache/?function=calculateTokensReinvested&expiry=30&force=${force}&network=${
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : "mainnet"
        }&contract_address=${Contract_obj.contractAddress}&sender_address=${
          Contract_obj.accounts[0]
        }`,
        {
          timeout: 50000,
        }
      )
      .then(resp => {
        console.log(resp.data.data, "setCetoIfReinvested");
        setTokens(parseInt(resp.data.data.toString()) / factor);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const ethData = async () => {
    setProcessing(false);
    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.accounts[0][0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 1 || web3_obj.networkId == 3)
    ) {
      var myDividends = await web3_obj.contract_instace.methods
        .myDividends(false)

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setDividends(parseInt(myDividends) / factor);
      console.log(web3_obj, myDividends, "myDividends");
      var myTokens = await web3_obj.contract_instace.methods
        .calculateTokensReinvested()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setTokens(parseInt(myTokens) / factor);

      var res = await web3_obj.contract_instace.methods
        .getAutoReinvestEntry()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log("res", res);
      if (res[0] == 0) {
        setAutoReinvest(false);
        setChecked(false);
      } else {
        setAutoReinvest(true);
        setChecked(true);
        setAutoReinvestEntry(res);
        setPeriod(parseInt(res[2]));
        setRewardPerInvocation(parseInt(res[1]) / factor);
        setMinimumDividendValue(parseInt(res[3]) / factor);
      }
      // setTokens(res.toString());
    }
  };
  const bnbData = async () => {
    setProcessing(false);
    setAutoReinvest(false);

    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.accounts[0][0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 97 || web3_obj.networkId == 56)
    ) {
      var myDividends = await web3_obj.contract_instace.methods
        .myDividends(false)

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setDividends(parseInt(myDividends) / factor);
      console.log(web3_obj, myDividends, "myDividends");
      var myTokens = await web3_obj.contract_instace.methods
        .calculateTokensReinvested()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setTokens(parseInt(myTokens) / factor);

      var res = await web3_obj.contract_instace.methods
        .getAutoReinvestEntry()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log("res", res);
      if (res[0] == 0) {
        setAutoReinvest(false);
        setChecked(false);
      } else {
        setAutoReinvest(true);
        setChecked(true);
        setAutoReinvestEntry(res);
        setPeriod(parseInt(res[2]));
        setRewardPerInvocation(parseInt(res[1]) / factor);
        setMinimumDividendValue(parseInt(res[3]) / factor);
      }
      // setTokens(res.toString());
    }
  };

  useEffect(() => {
    console.log(accounts, "ll");
    if (props.utils.blockchain == "eth") {
      ethData();
    } else if (props.utils.blockchain == "bnb") {
      bnbData();
    } else {
      Contract_obj.contract_instace && fetchData(0);
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          console.log("setNode event", e.data.message);

          if (
            window.tronWeb &&
            window.tronWeb.fullNode.host == metacoinConfig.fullHost
          ) {
            Contract_obj.contract_instace && fetchData(0);
          }
        }
        if (e.data.message && e.data.message.action == "setAccount") {
          Contract_obj.contract_instace && fetchData(0);
        }
      });
    }
  }, []);

  useEffect(() => {
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );
    props.utils.blockchain == "eth"
      ? ethData()
      : props.utils.blockchain == "bnb"
      ? bnbData()
      : Contract_obj.contract_instace && fetchData(1);
  }, [
    updated,
    props.utils.blockchain,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
  ]);

  const checkConnection = async v => {
    console.log(props.utils.blockchain, autoReinvest);
    if (props.utils.blockchain == "eth") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![1, 3].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        if (v == 1) {
          setOpen(true);
        } else if (v == 2) {
          stopAutoReinvest();
        }
      }
    } else if (props.utils.blockchain == "bnb") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![97, 56].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        if (v == 1) {
          setOpen(true);
        } else if (v == 2) {
          stopAutoReinvest();
        }
      }
    } else {
      if (
        !window.tronWeb ||
        (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
      ) {
        console.log("not loaded");
        setConnectModal(true);
      } else if (
        window.tronWeb &&
        window.tronWeb.fullNode.host != metacoinConfig["mainnet"].fullHost &&
        window.tronWeb.fullNode.host != metacoinConfig["shasta"].fullHost
      ) {
        console.log("catch");
        setConnectionIssue(1);
        setConnectModal(true);
      } else {
        window.tronWeb &&
          (await window.tronWeb.trx
            .getBalance(Contract_obj.accounts[0])
            .then(result => {
              console.log(result, "balance");
              setAccBalance(result);
            }));
        if (v == 1) {
          setOpen(true);
        } else if (v == 2) {
          stopAutoReinvest();
        }
      }
    }
  };
  const onReinvest = async () => {
    var offset = checked;
    if (
      autoReinvest == true &&
      rewardPerInvocation ==
        parseInt(autoReinvestEntry[1].toString()) / factor &&
      period == parseInt(autoReinvestEntry[2].toString()) &&
      minimumDividendValue == parseInt(autoReinvestEntry[3].toString()) / factor
    ) {
      offset = false;
    }
    if (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb") {
      try {
        setTxnLoading(true);
        setOpen(false);

        await web3_obj.reinvestFunc(
          offset,
          period,
          rewardPerInvocation,
          minimumDividendValue
        );

        // setUpdated(updated + 1);
        setTxnLoading(false);

        setTimeout(async () => {
          setUpdated(updated + 1);
        }, 1000);
      } catch (error) {
        console.log(error);
        if (error.message.includes("User denied transaction signature")) {
          console.error("User denied transaction signature");
        }
        setProcessing(false);
      }
    } else {
      try {
        await Contract_obj.reinvestFunc(
          offset,
          period,
          rewardPerInvocation,
          minimumDividendValue
        );

        // setUpdated(updated + 1);
        setProcessing(1);

        setOpen(false);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setProcessing(false);
        }, 5000);
      } catch (error) {
        console.log(error);
        setProcessing(false);
      }
    }
  };
  const stopAutoReinvest = async () => {
    if (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb") {
      try {
        setTxnLoading(true);
        await web3_obj.stopAutoReinvestFunc();
        setTxnLoading(false);

        setTimeout(async () => {
          setUpdated(updated + 1);
        }, 1000);
      } catch (err) {
        console.log("err", err);
        setTxnLoading(false);
      }
    } else {
      try {
        var res = await Contract_obj.stopAutoReinvestFunc();
        console.log("stopAutoReinvestFunc", res);
        // setUpdated(updated + 1);
        setProcessing(2);
        setTimeout(async () => {
          setUpdated(updated + 1);
          setProcessing(false);
        }, 6000);
      } catch (err) {
        console.log("err");
        setProcessing(false);
      }
    }
  };

  const getColor = type => {
    if (type == 1) {
      if (props.blockchain == "trx") {
        return "#002E8C";
      } else if (props.blockchain == "eth") {
        return "#464646";
      } else {
        return "#BF9C00";
      }
    } else if (type == 2) {
      if (props.blockchain == "trx") {
        return "#009EE4";
      } else if (props.blockchain == "eth") {
        return "#A53CFF";
      } else {
        return "#691AAA";
      }
    } else if (type == 3) {
      if (props.blockchain == "trx") {
        return "#435475";
      } else if (props.blockchain == "eth") {
        return "#000000";
      } else {
        return "#435475";
      }
    }
  };

  return (
    <div
      className={styles.currencies_container}
      style={{ background: props.blockchain == "eth" ? "#EBEBEB" : "white" }}
    >
      <p className={styles.price} style={{ color: getColor(1) }}>
        Dividends{" "}
        {/* <IconButton
          color="primary"
          style={{ padding: 0 }}
          onClick={() => setShowInfo(true)}
        >
          <HelpOutlineIcon
            style={{
              color: "#1FD0FF",
              width: "18px",
              height: "18px",
              margin: "0 10px",
            }}
          />
        </IconButton> */}
      </p>
      <div className={styles.divs} style={{ color: getColor(2) }}>
        {" "}
        {dividends || dividends == 0 ? dividends.toFixed(3) : ""}{" "}
        {props.utils.blockchain.toUpperCase()}
      </div>
      <div className={styles.cconvert}>
        <div className={styles.liquidity}>
          {" "}
          {props.utils.blockchain == "eth"
            ? props.utils.eth_liquidity
            : props.utils.blockchain == "bnb"
            ? props.utils.bnb_liquidity
            : props.utils.trx_liquidity}
          % Liquidity
        </div>
      </div>
      <div className={styles.cconvert} style={{ color: getColor(3) }}>
        <div className={styles.pc}>
          {tokens || tokens == 0 ? (
            <div className={styles.amount}>
              {parseInt(tokens).toFixed(3)}{" "}
              {props.utils.blockchain == "eth"
                ? "ECETO"
                : props.utils.blockchain == "bnb"
                ? "BCETO"
                : "CETO"}
            </div>
          ) : window.tronWeb &&
            window.tronWeb.defaultAddress.hex &&
            window.tronWeb.fullNode.host == metacoinConfig.fullHost ? (
            <div class="ellipses"></div>
          ) : (
            <div className={styles.amount}>
              {props.utils.blockchain == "eth"
                ? "ECETO"
                : props.utils.blockchain == "bnb"
                ? "BCETO"
                : "CETO"}
            </div>
          )}
          <div className={styles.acurrency}>(if reinvested)</div>
        </div>
        <Dropdown
          heading="tokens"
          className={styles.stat}
          value={[
            `${
              dividends || dividends == 0 ? dividends.toFixed(3) : ""
            } ${props.utils.blockchain.toUpperCase()}`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.USD).toFixed(2)
                : ""
            } USD`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.CHF).toFixed(2)
                : ""
            } CHF`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.EUR).toFixed(2)
                : ""
            } EUR`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.GIP).toFixed(2)
                : ""
            } GIP`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.KWD).toFixed(2)
                : ""
            } KWD`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.GBP).toFixed(2)
                : ""
            } GBP`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.JOD).toFixed(2)
                : ""
            } JOD`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.OMR).toFixed(2)
                : ""
            } OMR`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.BHD).toFixed(2)
                : ""
            } BHD`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.CAD).toFixed(2)
                : ""
            } CAD`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.INR).toFixed(2)
                : ""
            } INR`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.JPY).toFixed(2)
                : ""
            } JPY`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.HKD).toFixed(2)
                : ""
            } HKD`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.PAB).toFixed(2)
                : ""
            } PAB`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.AED).toFixed(2)
                : ""
            } AED`,
          ]}
          footer={[
            `${
              dividends || dividends == 0 ? dividends : ""
            } ${props.utils.blockchain.toUpperCase()}`,
            `${
              dividends || dividends == 0
                ? (dividends * props.utils.currencies.USD).toFixed(2)
                : ""
            } USD`,
          ]}
        />
      </div>
      {props.blockchain == "eth" || props.blockchain == "bnb" ? (
        <GreenButton
          style={{
            background: "transparent",
            color: props.blockchain == "eth" ? "#464646" : "#691AAA",
            border: `1px solid ${
              props.blockchain == "eth" ? "#464646" : "#691AAA"
            }`,
            boxShadow: "none",
          }}
          onClick={() => processing != 1 && checkConnection(1)}
        >
          Reinvest{" "}
        </GreenButton>
      ) : (
        <div
          id="button-wrappered"
          className={`${processing == 1 ? "checked" : ""}`}
          style={{
            background: "white",
            color: "#009EE4",
            marginTop: 0,
            boxShadow: "none",
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            {" "}
            <defs>
              {" "}
              <filter id="goo">
                {" "}
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
                />{" "}
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />{" "}
                <feBlend in="SourceGraphic" in2="goo" />{" "}
              </filter>{" "}
            </defs>
          </svg>
          <p
            onClick={() => processing != 1 && checkConnection(1)}
            className={`${processing == 1 ? "submited circle" : "submited"}`}
            style={{
              border: "1px solid #009EE4",
            }}
          >
            REINVEST{" "}
            <img
              className={`${processing == 1 ? "hideArrow" : ""}`}
              src={arrow}
            />
          </p>
          <div className="loader-wrapper">
            <span className="loader color1"></span>
            <span className="loader color2"></span>
          </div>
          <span className="loader color3"></span>

          <div className="check-wrapper">
            <svg
              version="1.1"
              width="65px"
              height="38px"
              viewBox="0 0 64.5 37.4"
            >
              <polyline className="check" points="5,13 21.8,32.4 59.5,5 " />
            </svg>
          </div>
        </div>
      )}

      {autoReinvest && autoReinvest == true ? (
        <a
          className={styles.invest}
          onClick={() => checkConnection(2)}
          style={{ marginTop: "30px" }}
        >
          {" "}
          {processing == 2 ? (
            <div class={`ellipses ${styles.Processing}`}>Processing</div>
          ) : (
            <p
              style={{ textDecoration: "underline" }}
              className={styles.investtext}
            >
              {" "}
              Stop Auto-reinvestment
            </p>
          )}
        </a>
      ) : (
        autoReinvest != null && (
          <div className={styles.invest}>
            {" "}
            <Checkbox
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked);
                setAutoReinvestModal(true);
                processing != 1 && checkConnection(1);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
              style={{ color: "#002E8C" }}
            />
            <p className={styles.investtext}>
              {" "}
              Auto-reinvest after every 24 hours
              <IconButton
                color="primary"
                style={{ padding: 0 }}
                onClick={() => set_show_auto_reinvest_popup(true)}
              >
                <HelpOutlineIcon
                  style={{
                    color:
                      props.blockchain == "eth"
                        ? "#691AAA"
                        : props.blockchain == "bnb"
                        ? "#EDC201"
                        : "#1FD0FF",
                    width: "18px",
                    height: "18px",
                    margin: "0 10px",
                  }}
                />
              </IconButton>
            </p>
          </div>
        )
      )}

      <Reinvestpopup
        open={open}
        handleClose={setOpen}
        dividends={dividends}
        onReinvest={onReinvest}
        usds={(dividends * props.utils.currencies.USD).toFixed(3)}
        tokens={tokens}
        timer={timer}
        my_tokens={tokens}
        autoReinvest={checked}
        setAutoReinvest={setChecked}
        period={period}
        setPeriod={setPeriod}
        rewardPerInvocation={rewardPerInvocation}
        setRewardPerInvocation={setRewardPerInvocation}
        minimumDividendValue={minimumDividendValue}
        setMinimumDividendValue={setMinimumDividendValue}
        accBalance={accBalance}
        autoReinvestModal={autoReinvestModal}
        setAutoReinvestModal={setAutoReinvestModal}
        blockchain={props.utils.blockchain}
        {...props}
      />
      <AutoReinvestModal
        show_auto_reinvest_popup={show_auto_reinvest_popup}
        set_show_auto_reinvest_popup={set_show_auto_reinvest_popup}
        blockchain={props.utils.blockchain}
      ></AutoReinvestModal>
      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
        setConnectModal={setConnectModal}
        blockchain={props.utils.blockchain}
      />
      <Info
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        blockchain={props.utils.blockchain}
      ></Info>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Reinvestdividends);
