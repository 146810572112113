import React, { useState } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import arrow from "../../public/Button - Icon.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const ConnectionModal = ({
  openModal,
  connection_issue,
  setConnectModal,
  blockchain,
}) => {
  const [bal, setbal] = useState(true);
  const [slug, setslug] = useState("");
  const [isavailable, setavail] = useState(true);

  const handleChange = e => {
    const { name, value } = e.target;
    setslug(value);
  };
  return (
    <Card>
      <div className={`modal ${openModal ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            <CloseIcon
              onClick={() => setConnectModal(false)}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>

          <div className="middiv l">
            <img src="https://res.cloudinary.com/dgdjabqwr/image/upload/v1610798729/cd3d/Group_5262_1_wbfdez.png"></img>
            <p className="warn">Welcome to the Jungle! </p>
            <p className="inf">
         
              {blockchain == "bnb"
                ? "Please switch to Binance Samart Chain. "
                : connection_issue == 1
                ? "Contract is not deployed on this network. Please change your network to "
                : `Please connect your ${
                    blockchain == "trx" ? "TronLink" : "Metamask"
                  } or other compatible web wallet ${blockchain == "eth" ? "to ethereum." : "."}`}
              <br></br>
              {blockchain == "bnb" && "Follow the steps below to set it up :"}
              {connection_issue == 1 && <b>Mainnet.</b>}
            </p>
            <p className="inf">
              {blockchain == "bnb" &&
                "Open Metamask > Click on the network dropdown > Select custom RPC > Fill in the details and save."}
            </p>

            {blockchain == "bnb" && (
              <ul>
                <span className = "detail">Details</span>
                <li className="inff">
                  Testnet * https://data-seed-prebsc-1-s1.binance.org:8545/ *
                  ChainID: 97 * Symbol: BNB * Block Explorer:
                  https://testnet.bscscan.com
                </li>
                <li className="inff">
                  {" "}
                  Mainnet * https://bsc-dataseed.binance.org/ * ChainID: 56 *
                  Symbol: BNB * Block Explorer: https://bscscan.com
                </li>
              </ul>
            )}
            {connection_issue == 1 && (
              <p>
                Don't know how?{" "}
                <a
                  href="https://www.youtube.com/watch?v=hFHVfFLSZo4"
                  target="_blank"
                >{` Click here`}</a>
              </p>
            )}

            {connection_issue == 0 && blockchain != "bnb" && (
              <p>
                Haven't installed{" "}
                {blockchain == "trx" ? "TronLink" : "Metamask"} yet?{" "}
                <a
                  href={
                    blockchain == "trx"
                      ? "https://www.tronlink.org/"
                      : "https://metamask.io/"
                  }
                  target="_blank"
                >{` Click here`}</a>
              </p>
            )}
          </div>
          <div className="middiv s">
            <img src="https://res.cloudinary.com/dgdjabqwr/image/upload/v1610798729/cd3d/Group_5262_1_wbfdez.png"></img>
            <p className="warn">Mobile User? </p>
            <p className="inf">
              {connection_issue == 1
                ? "Contract is not deployed on this network. Please change your network to "
                : "Use your TronLink DApp Browser:"}
              {connection_issue == 1 && <b>Mainnet.</b>}
            </p>

            {connection_issue == 0 && (
              <p className="txt1">
                {" "}
                {"Open app > “My” tab > Advanced Features > DApp Browser >"}
              </p>
            )}
            {connection_issue == 0 && (
              <a
                href="https://crystalelephant.net"
                target="_blank"
              >{`https://crystalelephant.net`}</a>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ConnectionModal;
