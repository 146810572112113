import React, { useEffect, useState } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";

import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import logo from "../../public/Button - Icon (1).svg";
import arrow from "../../public/Button - Icon.svg";
import WarningIcon from "@material-ui/icons/Warning";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const Sellpopup = ({
  open,
  handleClose,
  cryptoToTokens,
  crypto,
  usd,
  penalty,
  sell,
  tokens,
  amount,
  timer,
  accBalance,
  blockchain,
  factor,
}) => {
  const [bal, setbal] = useState(true);
  const [slug, setslug] = useState("");
  const [isavailable, setavail] = useState(true);
  const handleChange = e => {
    const { name, value } = e.target;
    setslug(value);
  };

  return (
    <Card>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            <span className="custom">Are you sure?</span>

            <CloseIcon
              onClick={() => handleClose(false)}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>
          <div className="midwrapper">
            <div className="rwrap">
              <div className="topd">
                <div className="cl">Sell Amount</div>
                <div className="rd">{usd} USD</div>
              </div>
              <div className="slug-txt-input">
                <span className="slug-val">{cryptoToTokens}</span>
                <div className="slug-check">
                  {blockchain == "trx"
                    ? "CETO"
                    : blockchain == "eth"
                    ? "ECETO"
                    : "BCETO"}
                </div>
              </div>
              {/* <CssTextField
                fullWidth
                name="referralCode"
                FormHelperTextProps={false}
                name="slug"
                value={cryptoToTokens}
                variant="outlined"
                InputProps={{
                  endAdornment: ,
                }}
              /> */}

              <div className="fee">
                <div className="f1">
                  <div className="cl">Penalty</div>
                  <div className="slug-check">
                    {penalty && (penalty / factor).toFixed(3)}{" "}
                    {blockchain.toUpperCase()}
                  </div>
                </div>
                <div className="f1">
                  <div className="cl">Transaction Fees</div>
                  <div className="slug-check">
                    {((amount - penalty) / 10 / factor).toFixed(3)}{" "}
                    {blockchain.toUpperCase()}
                  </div>
                </div>
                <hr />
                <div className="f1">
                  <div className="cl">Net Sale</div>
                  <div className="slug-check">
                    {crypto && (crypto / factor).toFixed(3)}{" "}
                    {blockchain.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className="cntner">
                {blockchain == "trx" && accBalance / factor < 28 ? (
                  <div className="warn-line">
                    {" "}
                    <WarningIcon
                      style={{ margin: "-6px auto", color: "#de3c3c" }}
                    ></WarningIcon>{" "}
                    <span>
                      Insufficient TRX in your wallet for transaction.
                    </span>
                  </div>
                ) : (
                  <div className="warn-line">
                    {" "}
                    <WarningIcon
                      style={{ margin: "-6px auto", color: "#de3c3c" }}
                    ></WarningIcon>{" "}
                    <span>
                      Please turn off auto-reinvest before selling to withdraw
                      from the contract.
                    </span>
                  </div>
                )}
                <div className="action-div">
                  <div className="lwrap"></div>
                  <div className="blocks">
                    <GreenButton
                      style={{
                        background: "#ffffff",
                        color: "#691AAA",
                        border: "1px solid #691AAA",
                        marginRight: "15px",
                        width: "200px",
                        boxShadow: "none",
                        marginBottom: "10px",
                      }}
                      onClick={() => sell()}
                    >
                      Confirm
                    </GreenButton>
                    <FormHelperText
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#7689B0",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      Silly Rabbit
                    </FormHelperText>
                  </div>
                  <div className="blocks">
                    <GreenButton
                      style={{
                        background: " #691AAA",
                        color: "#ffffff",
                        width: "210px",
                        boxShadow: "none",
                        marginBottom: "10px",
                      }}
                      onClick={() => handleClose(false)}
                    >
                      Cancel
                    </GreenButton>
                    <FormHelperText
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#7689B0",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      Smart Elephant
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Sellpopup;
