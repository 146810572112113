import Hourglass from "./contracts/Hourglass.json";

import wrappedConfig from "./wrapped-config";

var contractAddress;
var tronWeb;
try {
  const TronWeb = require("tronweb");

  window.addEventListener("load", async event => {
    tronWeb = window.tronWeb;
    contract_obj.tronWebProvider = window.tronWeb;
    console.log("page is fully loaded", window.tronWeb, tronWeb);
    var node_ =
      window.tronWeb &&
      (window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
        ? "shasta"
        : "mainnet");
    if (node_) {
      contractAddress = wrappedConfig[node_].contractAddress;
      console.log(contractAddress, "contractAddress");
      contract_obj.init(node_);
    }
  });
  // document.write(window.tronWeb.defaultAddress.base58)
} catch (err) {
  // alert(`The app looks not configured. Please run "npm run migrate" ${err}`);
}

const contract_obj = {
  tronWebProvider: tronWeb,
  contracts: {},
  accounts: [],
  contractAddress: contractAddress,
  contractAddressBase : null,
  // privateKey:
  //   "CBB6FDC134599AD3FF5D8E4A23E87EB1289BC6985A3380D8FAA35CDBA677DA05",
  feeLimit: 100000000,
  callValue: 0,
  contract_instace: null,
  init: async function (node_) {
    // console.log(node_,"NODE",metacoinConfig[node_].contractAddress)
    if (node_) {
      contract_obj.contractAddress = wrappedConfig[node_].contractAddress;
      contract_obj.contractAddressBase = wrappedConfig[node_].contractAddressBase;

      contract_obj.contract_instace =
        node_ && tronWeb && (await tronWeb.contract().at(this.contractAddress));
    }
    this.accounts = [
      this.tronWebProvider && this.tronWebProvider.defaultAddress.base58,
    ];
  },
  buyFunc: async function (ceto) {
    // console.log(this.contract_instace, "nnnn", trx, ref_by , this.accounts[0]);
    var data = await this.contract_instace
      .buy(ceto)
      .send({
        feeLimit: this.feeLimit,
      })
      .then(function (res) {
        if (res) {
          console.log(res, "tt11");
          return res
        }
      })
      .catch ((error) => {
        console.log("err", error);
      });
    return data
  },
 
  sellFunc: async function (wceto) {
    console.log(wceto, "ll");
    var wceto_to_sell = wceto * 1e6;
    var data = await this.contract_instace
      .sell(wceto_to_sell)
      .send({
        feeLimit: this.feeLimit,
        from : this.accounts[0]
      })
      .then(function (res) {
        if (res) {
          console.log(res.toString(), "sell");
          return res
        }
      })
      .catch((err) => console.error("err" , err));
      return data
  },
  
};

export default contract_obj;
