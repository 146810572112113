import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import logo from "../../public/Button - Icon (1).svg";
import arrow from "../../public/Button - Icon.svg";
import AutoReinvestModal from "../autoreinvest";
import MaxWidthDialog from "./advanced_settings";
import WarningIcon from "@material-ui/icons/Warning";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const Reinvestpopup = ({
  open,
  handleClose,
  dividends,
  onReinvest,
  usds,
  tokens,
  timer,
  my_tokens,
  autoReinvest,
  setAutoReinvest,
  period,
  setPeriod,
  rewardPerInvocation,
  setRewardPerInvocation,
  minimumDividendValue,
  setMinimumDividendValue,
  accBalance,
  autoReinvestModal,
  setAutoReinvestModal,
  blockchain,
  ...props
}) => {
  const [bal, setbal] = useState(true);
  const [slug, setslug] = useState();
  const [isavailable, setavail] = useState(true);
  const [settings, showSettings] = useState(false);
  const [type, setType] = useState(0);
  const [show_auto_reinvest_popup, set_show_auto_reinvest_popup] = useState(
    false
  );
  useEffect(() => {
    console.log(open, dividends, usds, "jkjk", window.location);
    dividends && setslug(dividends.toFixed(3));
  }, [dividends, usds]);
  useEffect(() => {
    setType(autoReinvestModal ? 1 : 0);
  }, [autoReinvestModal]);
  return (
    <Card>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            {autoReinvestModal}
            <span className="custom">
              {type == 0
                ? "Ride the Elephant."
                : "Reinvest once to enable auto reinvestment."}
            </span>

            <CloseIcon
              onClick={() => {
                handleClose(false);
                setAutoReinvestModal(false);
              }}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>
          <div className="midwrapper">
            <div className="rwrap">
              <div className="topd">
                <div className="cl">Dividends Amount</div>
                <div className="rd">{usds} USD</div>
              </div>

              <div className="slug-txt-input">
                <span className="slug-val">{slug}</span>
                <div className="slug-check">{blockchain.toUpperCase()}</div>
              </div>
              <div className="fee">
                <div className="f1">
                  <div className="cl">Transaction Fees</div>
                  <div className="slug-check">
                    {(slug / 10).toFixed(3)} {blockchain.toUpperCase()}
                  </div>
                </div>
                <hr />
                <div className="f1">
                  <div className="cl">Net Reinvestment</div>
                  <div className="slug-check">
                    {tokens && tokens.toFixed(2)}{" "}
                    {blockchain == "eth"
                      ? "ECETO"
                      : blockchain == "bnb"
                      ? "BCETO"
                      : "CETO"}
                  </div>
                </div>
              </div>
              <div className="invest">
                {" "}
                <div className="invest_1">
                  <Checkbox
                    checked={autoReinvest}
                    onChange={e => setAutoReinvest(e.target.checked)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    style={{ color: "#002E8C" }}
                  />
                  <p className="investtext">
                    {" "}
                    Auto-reinvest after every 24 hours
                    <IconButton
                      color="primary"
                      style={{ padding: 0 }}
                      onClick={() => set_show_auto_reinvest_popup(true)}
                    >
                      <HelpOutlineIcon
                        style={{
                          color: "#1FD0FF",
                          width: "18px",
                          height: "18px",
                          margin: "0 10px",
                        }}
                      />
                    </IconButton>
                  </p>
                </div>
                {autoReinvest && (
                  <div className="set" onClick={() => showSettings(true)}>
                    Advanced Settings
                  </div>
                )}
              </div>
              <div className="cntner">
                {blockchain == "trx" && accBalance / 1e6 < 32 && (
                  <div className="warn-line">
                    {" "}
                    <WarningIcon
                      style={{ margin: "-6px auto", color: "#de3c3c" }}
                    ></WarningIcon>{" "}
                    <span>
                      Insufficient TRX in your wallet for transaction.
                    </span>
                  </div>
                )}
                <div className="action-div">
                  <div className="lwrap"></div>
                  <div className="blocks">
                    <GreenButton
                      style={{
                        background: "#ffffff",
                        color: "#691AAA",
                        border: "1px solid #691AAA",
                        marginRight: "15px",
                        width: "200px",
                        boxShadow: "none",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        handleClose(false);
                        setAutoReinvestModal(false);
                      }}
                    >
                      Cancel
                    </GreenButton>
                    <FormHelperText
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#7689B0",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      Silly Rabbit
                    </FormHelperText>
                  </div>
                  <div className="blocks">
                    <GreenButton
                      style={{
                        background: " #691AAA",
                        color: "#ffffff",
                        width: "210px",
                        boxShadow: "none",
                        marginBottom: "10px",
                      }}
                      onClick={() => onReinvest()}
                    >
                      Confirm
                    </GreenButton>
                    <FormHelperText
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#7689B0",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      Smart Elephant
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AutoReinvestModal
        show_auto_reinvest_popup={show_auto_reinvest_popup}
        set_show_auto_reinvest_popup={set_show_auto_reinvest_popup}
      ></AutoReinvestModal>
      <MaxWidthDialog
        showSettings={showSettings}
        settings={settings}
        period={period}
        setPeriod={setPeriod}
        rewardPerInvocation={rewardPerInvocation}
        setRewardPerInvocation={setRewardPerInvocation}
        minimumDividendValue={minimumDividendValue}
        setMinimumDividendValue={setMinimumDividendValue}
        blockchain={blockchain}
        {...props}
      ></MaxWidthDialog>
    </Card>
  );
};

export default Reinvestpopup;
