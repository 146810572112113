import styled from "styled-components";
export const Card = styled.div`
  .modal-content {
    position: relative;
    width: 59vw;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 600px) {
      width: 90vw;
      padding: 30px 1rem;
    }
    .upperdiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 60px;
      padding: 30px 40px 40px 60px;

      font-size: 32px;
      color: #691aaa;
      font-weight: 400;
      .custom {
        color: #691aaa;
        font-size: 32px;
        font-weight: 300;
      }
    }

    .cl {
      color: #002e8c;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .action-div {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }

    .middiv {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .warn {
        color: #162752;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        margin: 30px 0 15px;
      }
      .left {
        margin: auto;
        .d1 {
          text-align: left;
          color: #009ee4;
          opacity: 1;
          font-size: 36px;
          img {
            margin-right: 10px;
          }
          span {
            font-size: 22px;
          }
        }
        .d2 {
          text-align: left;
          color: #7689b0;
          opacity: 1;
          font-size: 18px;
          font-weight: weight 500;
          span {
            color: #26de98;
          }
        }
      }

      .inf {
        color: #435475;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        width: 65%;
        margin-bottom: 50px;
      }
    }
    .bottom-div {
      padding: 15px 30px;
      display: flex;
      border: 1px solid #7689b04f;
      .t1 {
        color: #691aaa;
        font-size: 18px;
        font-weight: 600;
      }
      .t2 {
        color: #009ee4;
        font-size: 18px;
        margin: 0 12px;
        font-weight: 600;
        cursor: pointer;
      }
      .sec-1 {
        margin: 0 12px;
        cursor: pointer;

        img {
          width: 24px;
          margin-right: 12px;
          height: 20px;
        }
        span {
          letter-spacing: 3.2px;
          color: #435475;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .last {
        flex: 1;
      }
    }
  }
  .modal-close {
    position: absolute;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 50px;
  }
  .infor {
    display: flex;
    justify-content: center;
    .box-1 {
      padding: 0 2rem;
      width: 35%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    hr {
      transform: rotate(90deg);
    }
    .box-1:not(:last-child) {
      border-right: 1px solid grey;
    }
    .txt1 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #009ee4;
      opacity: 1;
      text-align: right;
    }
    .txt2 {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #162752;
      opacity: 1;
      text-align: right;
    }
    .lft {
      text-align: left;
    }
  }
`;
