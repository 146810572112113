import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

class Main extends Component {
  constructor(props) {
    super(props);
    this.deferredPrompt = null;
    this.state = {
      currentRoute: ""
    };
  }

  componentWillUnmount() {}

  renderChildrens() {
    const { location } = this.props;
    const that = this;
    let width = "80%";
    let marginLeft = "20%";
    let position = "fixed";
    if (location && location.pathname == "/") {
      width = "100%";
      marginLeft = "0%";
      position = "relative";
    }
    return React.Children.map(this.props.children, child => {
      const clonedProps = {
        ...child.props
      };
      return (
        <div style={{ overflow: "hidden" }}>
          {React.cloneElement(child, clonedProps)}
        </div>
      );
    });
  }

  render() {
    // globalStyles();
    const { location } = this.props;
    console.log(this.props);
    return <div>{this.renderChildrens()}</div>;
  }
}

function mapStateToProps(state) {
  console.log(state);
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, actions)(Main);
