import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import Navbar from "../components/navbar/navbar.component";
import SubNavbar from "../components/sub-navbar/sub-navbar.component";
import Actionbar from "../components/actionbar/actionbar";
import SocialCard from "../components/social-card/social-card.component";
import TokensTimeline from "../components/tokens-timeline/tokens-timeline.component";
import AdsSlider from "../components/ads-slider/ads-slider.component";
import CinemaDraftButton from "../components/cinema-draft-button/cinema-draft-button.component";
import Footer from "../components/footer/footer.component";
import Buysell from "../components/buyselltokens/buysell";
import { connect } from "react-redux";
import * as actions from "../actions";
import styles from "./../styles/Social.module.scss";
import twittericon from "../public/twitter-icon.svg";
import discord from "../public/Discord - Icon.svg";
import telegram from "../public/Telegram - Icon.svg";
import blog from "../public/Blog.svg";
import Contract_obj from "../getTronweb";
import ReactLoading from "react-loading";
import ConnectionModal from "../components/connectionpopup";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomLoader from "../components/Loader.js";
import metacoinConfig from "../metacoin-config.js";
import eth from "../public/ethe.png";
import bnb from "../public/bnb.png";
import ArrowInButton from "../public/herosection-button-icon.svg";
import Banner from "../components/banner";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Tutorial = props => {
  const [state, setstate] = useState();
  const [contracts, setContracts] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [balance, setBalance] = useState();
  const [contractBalance, setContractBalance] = useState();
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(0);
  const [timer, setTimer] = useState();
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [scrolling, setScrolling] = useState("up");
  const [txnLoading, setTxnLoading] = useState(false);

  const fetchData = async () => {
    console.log("test", Contract_obj.tronWebProvider, Contract_obj.accounts[0]);

    window.tronWeb &&
      (await Contract_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : "mainnet"
      ));
    console.log("trooon", Contract_obj);
    if (Contract_obj.tronWebProvider) {
      try {
        await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.contractAddress)
          .then(result => {
            console.log(result, "bal");
            setContractBalance(result);
          });
        await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.accounts[0])
          .then(result => {
            console.log(result, "user balance", Contract_obj.accounts[0]);
            setBalance(result);
            setLoading(false);
          });
      } catch (error) {
        // Catch any errors for any of the above operations.

        console.error(error);
      }
    }
  };
  useEffect(() => {
    console.log("hii", window);
    window.addEventListener("message", function (e) {
      if (e.data.message && e.data.message.action == "setNode") {
        console.log("setNode event", e.data.message);

        if (
          window.tronWeb &&
          window.tronWeb.fullNode.host != metacoinConfig.fullHost
        ) {
          // console.log("catch")
          // setConnectionIssue(1);
          // setConnectModal(true);
          setLoading(false);
        } else {
          setUpdated(updated + 1);
          // setConnectModal(false);

          fetchData();
        }
      }
    });
    document.addEventListener("readystatechange", event => {
      if (event.target.readyState == "complete") {
        console.log(window, event, "windowww");
        fetchData();
        setTimeout(() => {
          if (
            !window.tronWeb ||
            (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
          ) {
            console.log("not loaded");
            // setConnectModal(true);
            setLoading(false);
            setUpdated(updated + 1);
          } else if (
            window.tronWeb &&
            window.tronWeb.fullNode.host != metacoinConfig.fullHost
          ) {
            // console.log("catch")
            // setConnectionIssue(1);
            // setConnectModal(true);
            setLoading(false);
          }
        }, 3000);
      }
    });
  }, [
    Contract_obj.tronWebProvider,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
    window.tronWeb && window.tronWeb.fullNode.host,
    window.tronWeb && window.tronWeb,
    window,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    var lastScrollTop = 0;
    window.addEventListener(
      "scroll",
      function () {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (window.scrollY == 0) {
          setScrolling("up");
        } else {
          if (st > lastScrollTop) {
            // downscroll code
            setScrolling("down");
          } else {
            // upscroll code
            setScrolling("up");
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
    console.log("hii", document);
    document.addEventListener("readystatechange", event => {
      console.log(event, "event");
      if (event.target.readyState == "complete") {
        console.log(window, event, "windowww");
        fetchData();
        setTimeout(() => {
          if (
            !window.tronWeb ||
            (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
          ) {
            console.log("not loaded");
            // setConnectModal(true);
            setLoading(false);
          } else if (
            window.tronWeb &&
            window.tronWeb.fullNode.host != metacoinConfig.fullHost
          ) {
            // console.log("catch")
            // setConnectionIssue(1);
            // setConnectModal(true);
            setLoading(false);
          }
        }, 3000);
      }
    });
    setTimeout(() => {
      if (
        !window.tronWeb ||
        (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
      ) {
        console.log("not loaded");
        // setConnectModal(true);
        setLoading(false);
      } else if (
        window.tronWeb &&
        window.tronWeb.fullNode.host != metacoinConfig.fullHost
      ) {
        // console.log("catch")
        // setConnectionIssue(1);
        // setConnectModal(true);
        setLoading(false);
      }
    }, 3000);
    fetchData();
  }, []);
  return loading ? (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      <CustomLoader></CustomLoader>
    </div>
  ) : (
    <div
      className={styles.social}
      style={{
        backgroundColor:
          props.utils.blockchain == "eth" ? "#E8E8E8" : "#f1f3fc",
      }}
    >
      <Banner {...props}></Banner>

      <Navbar {...props} page={12} blockchain={props.utils.blockchain} />
      <SubNavbar
        {...props}
        step={0}
        updated={updated}
        blockchain={props.utils.blockchain}
      />
      <div className={styles.social_container}>
        <Actionbar
          {...props}
          contractBalance={contractBalance}
          balance={balance && balance}
          updated={updated}
          flag={scrolling == "up" ? 1 : 0}
          page={1}
          blockchain={props.utils.blockchain}
        />

        <div className={styles.social__body}>
          <div className={styles.social__bodyLeft}>
            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/NJHaYm7Nm6U"
            ></iframe>
            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/OzN6nPAedq8"
            ></iframe>
            <div className={styles.social__bodyLeftHalfCards}>
              <iframe
                width="48%"
                height="500"
                src="https://www.youtube.com/embed/vUc297Nmumo"
              ></iframe>
              <iframe
                width="48%"
                height="500"
                src="https://www.youtube.com/embed/40T0YRlKtK4"
              ></iframe>

              <iframe
                width="48%"
                height="500"
                src="https://www.youtube.com/embed/bgidy8_l55c"
              ></iframe>
              <iframe
                width="48%"
                height="500"
                src="https://www.youtube.com/embed/2zYeBVixVBI"
              ></iframe>
              <iframe
                width="48%"
                height="500"
                src="https://www.youtube.com/embed/liqQUVB454s"
              ></iframe>
              <iframe
                width="48%"
                height="500"
                src="https://www.youtube.com/embed/ZjkSAmTCbA8"
              ></iframe>
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/66yH3z2fUNk"
              ></iframe>
            </div>
          </div>
          <div className={styles.social__bodyRight}>
            <Buysell
              theme={0}
              width={1}
              contract={contracts}
              accounts={accounts && accounts}
              updated={updated}
              setUpdated={setUpdated}
              timer={timer}
              blockchain={props.utils.blockchain}
              setTxnLoading={setTxnLoading}
              {...props}
            />
          </div>{" "}
        </div>

        {/* <AdsSlider
        ads={["/ads-banner.jpg", "/ads-banner.jpg", "/ads-banner.jpg"]}
      /> */}
      </div>
      <Footer blockchain={props.utils.blockchain} />
      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Tutorial);
