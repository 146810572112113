import styled from "styled-components";
export const Card = styled.div`
  .modal-content {
    position: relative;
    padding: 30px 40px 40px 60px;
    width: 59vw;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 600px) {
      width: 90vw;
      padding: 30px 1rem;
    }
    .upperdiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 60px;
      .custom {
        color: #691aaa;
        font-size: 32px;
        font-weight: 300;
      }
    }

    .midwrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
      .rwrap {
        display: flex;
        flex-direction: column;
        flex: 1;
        .slug-check {
          font-size: 20px;
          font-weight: 500;
          color: #009ee4;
        }
        .cl {
          color: #002e8c;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 12px;
        }
        .MuiOutlinedInput-root {
          font-family: "Poppins", sans-serif;
        }
        .MuiInputBase-input {
          color: #002e8c !important;
          font-size: 16px;
          font-weight: 500 !important;
          padding: 20px 40px;
        }
        .topd {
          display: flex;
          justify-content: space-between;

          .rd {
            color: #7689b0;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .fee {
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          .f1 {
            display: flex;
            justify-content: space-between;
          }
          hr {
            width: 100%;
            background-color: #bac4d7;
            height: 1px;
            margin: 0 0 10px;

          }
        }
        .action-div {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          @media screen and (max-width: 600px) {
            justify-content: center;
            flex-wrap: wrap;
            button {
              margin-bottom: 1rem;
            }
          }
        }
      }
      .lwrap {
        display: flex;
        flex-direction: column;
        .bluediv {
          background-color: #002e8c;
          padding: 50px 17px 50px 20px;
          border-radius: 20px;
          width: 450px;
          margin-right: 63px;
          margin-bottom: 34px;
          @media screen and (max-width: 600px) {
            width: 80vw;
            margin-right: 0;
          }
        }

        .count-down {
          .times {
            display: flex;
            .time {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 5px;
              .num {
                font-size: 40px;
                font-weight: 300;
              }
              .txt {
                color: #7689b0;
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
          .dt {
            color: #7689b0;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .balancediv {
        display: flex;
        justify-content: space-between;

        .balances {
          @media screen and (max-width: 600px) {
            width: 26vw;
            /* margin: 0 0 2rem; */
          }
          .tbal {
            color: #a1b5e0;
            font-size: 18px;
            font-weight: 400;
          }

          .dbal {
            color: #ffffff;
            font-size: 32px;
            font-weight: 500;
            span {
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }

      hr {
        width: 100%;
        background-color: #002062;
      }

      .pl {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .add {
          color: #ffffff;
          font-size: 20px;
          font-weight: 500;
        }

        .bal {
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 600px) {
            width: 17vw;
            /* margin: 0 0 2rem; */
          }
          .tbal {
            color: #a1b5e0;
            font-size: 16px;
            font-weight: 400;
          }
          .dbal {
            color: #ffffff;
            font-size: 22px;
            font-weight: 500;
            span {
              font-size: 15px;
              font-weight: 500;
            }
          }
          .y {
            color: #a1b5e0;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }

    .ref-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
      margin-bottom: 90px;
      .domain {
        color: #7689b0;
        font-size: 24px;
        font-weight: 500;
        margin-top: 30px;
        margin-right: 17px;
      }

      .slug {
        display: flex;
        flex-direction: column;
        flex: 1;
        .MuiOutlinedInput-root {
          font-family: "Poppins", sans-serif;
        }

        .slug-check {
          display: flex;
          align-items: center;
          min-width: 130px;
          .is-available {
            color: #7689b0;
            opacity: 1;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .MuiInputBase-input {
          color: #002e8c !important;
          font-size: 24px !important;
          font-weight: 500 !important;
          padding: 20px 40px;
        }
        .cl {
          color: #002e8c;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 12px;
        }
      }
    }

    .middiv {
      display: flex;
      flex-direction: column;
      align-items: center;

      .warn {
        color: #002e8c;
        font-size: 44px;
        font-weight: 500;
        font-style: italic;
        text-align: center;
        margin-bottom: 15px;
      }

      .inf {
        color: #162752;
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        width: 65%;
        margin-bottom: 50px;
      }
    }
  }
  .modal-close {
    position: absolute;
  }
`;
