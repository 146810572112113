const QUESTIONS_ARRAY = [
  {
    question: "Can I make money with CETO?",
    answer:
      "<div>Sure, why not? Through exchanging TRX/ETH/BNB for CETO, you can earn in several ways:</div><ul><li>receiving dividends through referral fees</li><li>increase in price of your chosen coin</li><li>increase in price of CETO, due to the amount of TRX/ETH/BNB in the contract</li><li>receiving dividends through referral fees</li></ul><div>Are you guaranteed to make money? Of course not! Only fools and liars make guarantees - we’re Elephants.</div>",
    isOpen: false,
  },
  {
    question: "Is CETO a security?",
    answer:
      "<div>No. Because of the autonomous, decentralized nature of the CETO contract, CETO should not be considered as a security. We don’t control how the CETO contract works after it has launched (so read our open-source contract, boys and girls). We have to buy CETO at the same price as everyone else at the same time as everyone else. The CETO smart contract <b>does not offer or promise a rate of return</b> - it is for entertainment purposes only. And don’t look to us for financial advice - we’re Elephants!</div>",
    isOpen: false,
  },
  // {
  //   question: "Why TRX and not ETH ?",
  //   answer:
  //     "<div>The gas is too damn high! Transaction fees for Ethereum are not only wildly variable, but also more expensive when the network is congested. All it takes is a CryptoKitties or P3D-connected FOMO3D game to get hot for a few days to send <b>transaction fees soaring.</b> We were faced with the choice of building this out on the Ethereum blockchain - with the potential for an average transaction fee of $15-$30 USD - or on the cheaper, faster Tron blockchain, where your average network transaction fee is less than a cent. </div><div>Yes, ETH is popular right now, but Tron is the peoples’ blockchain.</div>",
  //   isOpen: false,
  // },
  {
    question: "How do I get TRX/ETH/BNB?",
    answer:
      "<div>Tron, Ethereum, and BNB (TRX/ETH/BNB) are available at several cryptocurrency exchanges around the world. Most likely, you will need a fiat-to-crypto gateway like <a target = '_blank' href = 'http://gemini.com/share/mwk48nu7'>Gemini</a> or <a target = '_blank' href = 'https://changenow.io/exchange?fiatMode=true&from=usd&to=trx'>ChangeNow </a>in order to get started. Also the <a target_='_blank' href='https://www.exodus.io/'>Exodus</a> wallet has a built-in exchange.</div>",
    isOpen: false,
  },
  {
    question: "Why are there penalties for early withdrawal?",
    answer:
      "<div>We want to establish a community around the <b>profitability of patience</b>. Too often, these hourglass contracts get pumped-and-dumped, ruining whatever value and trust tokenholders had in the contract.</div><div>By encouraging at least a 30 day hold period, we believe fiscal responsibility and, well, <i>greed</i> will trump fear. Instead of everyone dumping at once, every day will be someone’s thirtieth day. But if fear outstrips greed, leaving the contract early will make bad business sense - and increase dividends for the rest of us. That’s right: Your penalty for leaving within 30 days of deposit goes to the other CETO tokenholders.</div><div>You’re free to withdraw your money anytime you want. However, if you withdraw before 30 days of your deposit or reinvestment, it’s gonna cost you.</div>",
    isOpen: false,
  },
  {
    question:
      "If I withdraw dividends without reinvesting, is there a penalty?",
    answer:
      "<div>Nope. You can withdraw your dividends at any time penalty-free and transaction fee-free!</div>",
    isOpen: false,
  },
  {
    question:
      "If I withdraw non-reinvested dividends and principal before its 30 days are up, is there a penalty?",
    answer:
      "<div>Only on your principal. First, your principal is taxed its penalty, if any; then our standard 10% CETO transaction fee; and finally, your dividends are withdrawn penalty- and tax-free alongside your principal.</div>",
    isOpen: false,
  },
  {
    question: "How can I earn CETO for free?",
    answer:
      "<div>Sign up for a CETO wallet and share your referral link with others; you will receive 33% of the dividends of your referrals’ CETO buys only (transfers and sells are excluded). But don’t be obnoxious about it; nobody loves a salesman! Tell, don’t sell: let your <b>gains</b> do the talking.</div>",
    isOpen: false,
  },
  {
    question: "Is CETO a one-to-one value ratio with TRX/ETH/BNB?",
    answer:
      "<div>No. The price of CETO is set by the amount of your chosen coin in the contract. So the greater the amount of TRX/ETH/BNB in the contract, the higher the price to buy or sell CETO.</div>",
    isOpen: false,
  },
  {
    question: "Is CETO a MLM (multi-level marketing) scheme?",
    answer:
      "<div>No. The contract only rewards 33% of your direct referrals’ transaction fees to you. Any of your referral’s referrals’ fees go to them.</div>",
    isOpen: false,
  },
  {
    question:
      "Are we associated with the original hourglass contract, P3D, or its development team Team JUST?",
    answer:
      "<div>Hell no. But if it ain’t broke, don’t break it. A lot of the questions you may have can be answered in the <a target='_blank' href = 'https://powh3d.hostedwiki.co/'>wiki from the P3D contract</a>. We didn’t invent the hourglass smart contract - we just made it better. </div><div>No one here has worked for Team JUST, sympathizes with Nazis, has ever done a rug-pull, or scammed its community. </div><div>Just like <b>Dottie</b>, our Crystal Elephant, we are <b>smart, wise, and loyal.</b></div>",
    isOpen: false,
  },
  {
    question: "Why is there more than one CETO?",
    answer:
      "<div>We at the CETO Foundation are platform agnostic. While we started the whole CETO economy on the Tron blockchain, we will expand to different blockchains as we see fit - creating awesome, high-volume transactional products for all. </div>",
    isOpen: false,
  },
  {
    question:
      "Will the dividends all end up being in one pool in my wallet or will they be separated by the type of transaction( TRX vs. ETH vs. BNB, etc.)?",
    answer: "<div>Dividends will end up being separated by chain.</div>",
    isOpen: false,
  },
  {
    question: "When will this end?",
    answer:
      "<div>That’s entirely up to you. The contract itself is designed to run for as long as the internet itself. How profitable it will be is up to you, your friends, and the rest of our profit-hungry little pack of pachyderms. Whether just for thirty days or thirty years or more, Crystal Elephant Token will be here for you to enjoy.</div>",
    isOpen: false,
  },
];

export default QUESTIONS_ARRAY;
