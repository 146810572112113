import React, { useState, useRef, useEffect } from "react";
import styles from "./dropdown.module.scss";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
const Dropdown = ({ heading, value, footer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const valueRef = useRef(null);

  const toggleDrop = e => {
    setIsOpen(isOpen => !isOpen);
  };
  useEffect(() => {
    setIsOpen(false);
    window.addEventListener('click', function(e){   
      if (document.getElementById('clickboxx') && document.getElementById('clickboxx').contains(e.target)){
        // Clicked in box
        // toggleDrop()
        console.log("")
      } else{
        // Clicked outside the box
        setIsOpen(false)
      }
    });
  }, []);
  return (
    <div className={styles.dividends__drop} ref={valueRef}>
      <div className={styles.dividends__dropMainValue} onClick={() => toggleDrop()} id = "clickboxx">
        {value[0]}
        <ArrowDropDownIcon />
      </div>
      {isOpen && (
        <div
          className={styles.dividends__dropSubValue}
          data-style-open={isOpen}
          onClick = {() => setIsOpen(false)}

        >
          {value.map((v, i) => (
            <span key={`tokens__${i}`} onClick={toggleDrop}>
              {v}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
