import React from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import loader_img from "../public/loader__img.png";
// import "../styles/loader.css"
const useStyles = makeStyles(theme => ({
  root: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "45%",
    },
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    margin: "auto",
  },
  load: {
    width: "70%",
    height: "2.5px",
    margin: "20px auto",
    background: "#e1bdff",
  },
}));
const innerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ba68c8",
    },
  },
});
export default function CustomLoader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={loader_img}></img>

      {/* <img src = "" ></img> */}
      <ThemeProvider theme={innerTheme}>
        <LinearProgress className={classes.load} color="secondary" />
      </ThemeProvider>
    </div>
  );
}
