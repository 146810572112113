import React, { useState, useEffect } from "react";
import axios from "axios";
import Dividends from "../dividends/dividends.component";
import Dividendss from "../dividends/dividends_";

import { connect } from "react-redux";
import * as actions from "../../actions";
import Contract_obj from "../../getTronweb";
import metacoinConfig from "../../metacoin-config.js";
import styles from "./currency.module.scss";
import eceto_logo from "../../public/ECETO-Logo.png";
import bceto_logo from "../../public/BCETO-Logo.png";
import logo from "../../public/PNG transparent file 03@2x.png";
import blue_arrow from "../../public/blue_arrow.png";
import tron from "../../public/tron.png";
import eth from "../../public/eth-img.png";
import binance from "../../public/binance.png";
const Currency = ({
  theme,
  contract,
  accounts,
  updated,
  setUpdated,
  ...props
}) => {
  const [tokens, setTokens] = useState();
  const [dividends, setDividends] = useState();
  const [balance, setBalance] = useState();

  const [cetoIfReinvested, setCetoIfReinvested] = useState();
  const [usd, setUsd] = useState();
  const [currenyArray, setCurrenyArray] = useState([]);
  const [blockchain, setBlockchain] = useState(props.blockchain);
  useEffect(() => {}, []);
  const handleChange = async e => {
    console.log(e);
    props.setCurrencies(e);

    await props.setBlockchain(e);
    // window.location.reload(false);
    if (e == "eth") {
      props.history.push({ pathname: "/eceto/buy" });
    } else if (e == "bnb") {
      props.history.push({ pathname: "/bceto/buy" });
    } else {
      props.history.push({ pathname: "/ceto/buy" });
    }
  };

  return (
    <div className={styles.holdings}>
      <span
        className={styles.holdings__title}
        style={{
          color:
            props.blockchain == "eth"
              ? "#464646"
              : props.blockchain == "bnb"
              ? "#BF9C00"
              : "#002E8C",
        }}
      >
        More CETO
      </span>

      <div className={styles.holdingsCards}>
        {!(props.blockchain == "trx") && (
          <div
            className={`${styles.holdingsCard}`}
            onClick={() => handleChange("trx")}
          >
            <img src={logo} className={styles.logo}></img>
            <div className={styles.exchange}>
              <div className={styles.txt}>Exchange</div>
              <img alt="landing_imgs" src={blue_arrow} width="20px" />
            </div>
            <div className={styles.foot}>
              <img src={tron}></img>
            </div>
          </div>
        )}
        {!(props.blockchain == "eth") && (
          <div
            className={`${styles.holdingsCard} ${styles.black}`}
            onClick={() => handleChange("eth")}
          >
            <img src={eceto_logo} className={styles.logo}></img>
            <div className={styles.exchange}>
              <div className={styles.txt}>Exchange</div>
              <img alt="landing_imgs" src={blue_arrow} width="20px" />
            </div>
            <div className={styles.foot}>
              <img src={eth}></img>
            </div>
          </div>
        )}
        {!(props.blockchain == "bnb") && (
          <div
            className={`${styles.holdingsCard} ${styles.black}`}
            onClick={() => handleChange("bnb")}
          >
            <img src={bceto_logo} className={styles.logo}></img>
            <div className={styles.exchange}>
              <div className={styles.txt}>Exchange</div>
              <img alt="landing_imgs" src={blue_arrow} width="20px" />
            </div>
            <div className={styles.foot}>
              <img src={binance}></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  // console.log(state, "state");
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Currency);
