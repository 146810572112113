import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { InputNumber, Input } from "antd";
import { Select } from "antd";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import WarningIcon from "@material-ui/icons/Warning";
const currencies = [
  {
    value: "60",
    label: "1 min",
  },
  {
    value: "600",
    label: "10 min",
  },
  {
    value: "3600",
    label: "1 hour",
  },
  {
    value: "43200",
    label: "12 hours",
  },
  {
    value: "86400",
    label: "24 hours",
  },
];
const { Option } = Select;
const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  flexx: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: "500",
  },

  head: {
    fontSize: "17px",
    color: "#002E8C",
    fontWeight: "500",
    margin: "20px",
    fontFamily: "Poppins",
  },
  txt: {
    margin: "auto 10px",
  },
  select: {
    fontFamily: "Poppins",
  },
}));

export default function MaxWidthDialog({
  showSettings,
  settings,
  period,
  setPeriod,
  rewardPerInvocation,
  setRewardPerInvocation,
  minimumDividendValue,
  setMinimumDividendValue,
  ...props
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [currency, setCurrency] = React.useState("1 min");
  const [minimumDividendValue__, setMinimumDividendValue__] = useState(
    props.blockchain == "trx" ? 50 : props.blockchain == "eth" ? 0.25 : 0.03
  );
  const [rewardPerInvocation__, setRewardPerInvocation__] = useState(
    props.blockchain == "trx" ? 20 : props.blockchain == "eth" ? 0.025 : 0.003
  );
  const handleChange = event => {
    setCurrency(event.target.value);
    setPeriod(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    showSettings(false);
  };

  useEffect(() => {
    setCurrency(period);
  }, [period]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={settings}
        aria-labelledby="max-width-dialog-title"
      >
        <div className={classes.head}>Advanced Settings</div>
        <DialogContent>
          <div className={classes.flexx}>
            <span className={classes.txt}> I want to reinvest every </span>
            <TextField
              id="standard-select-currency"
              select
              value={currency}
              onChange={handleChange}
              style={{ fontFamily: "Poppins", fontWeight: "500" }}
            >
              {currencies.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <span className={classes.txt}>for a fee of </span>
            <Input
              style={{
                borderTop: "none",
                borderBottom: "1px solid #000000de",
                borderRight: "none",
                borderLeft: "none",
                width: "98px",

                // color: "#009EE4"
              }}
              type="number"
              value={rewardPerInvocation}
              defaultValue={rewardPerInvocation}
              onChange={e => setRewardPerInvocation(e.target.value)}
              suffix={props.blockchain && props.blockchain.toUpperCase()}
            />{" "}
            <span className={classes.txt}>
              {" "}
              and my dividends should be at least{" "}
            </span>
            <Input
              type="number"
              style={{
                borderTop: "none",
                borderBottom: "1px solid #000000de",
                borderRight: "none",
                borderLeft: "none",
                width: "98px",
                // color: "#009EE4"
              }}
              onChange={e => {
                console.log(e);
                setMinimumDividendValue(e.target.value);
              }}
              value={minimumDividendValue}
              defaultValue={minimumDividendValue}
              suffix={props.blockchain && props.blockchain.toUpperCase()}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent:
              (parseFloat(rewardPerInvocation) <
                (props.blockchain == "trx"
                  ? 20
                  : props.blockchain == "eth"
                  ? 0.025
                  : 0.8) ||
                parseFloat(rewardPerInvocation) >
                  parseFloat(minimumDividendValue)) &&
              "center",
          }}
        >
          {parseFloat(rewardPerInvocation) < rewardPerInvocation__ ? (
            <div className="warn-line" style={{ margin: "20px 0" }}>
              {" "}
              <WarningIcon
                style={{ margin: "-6px auto", color: "#de3c3c" }}
              ></WarningIcon>{" "}
              <span>
                Fee must be greater than or equal to{" "}
                {props.blockchain == "trx"
                  ? 20
                  : props.blockchain == "eth"
                  ? 0.025
                  : 0.8}{" "}
                {props.blockchain && props.blockchain.toUpperCase()}.
              </span>
            </div>
          ) : parseFloat(rewardPerInvocation) >
            parseFloat(minimumDividendValue) ? (
            <div className="warn-line" style={{ margin: "20px 0  " }}>
              {" "}
              <WarningIcon
                style={{ margin: "-6px auto", color: "#de3c3c" }}
              ></WarningIcon>{" "}
              <span>Dividends must be greater than the reward.</span>
            </div>
          ) : (
            <Button
              onClick={handleClose}
              color="primary"
              style={{ fontWeight: "500", fontFamily: "Poppins" }}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
