import React, { useState, useEffect } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import axios from "axios";
import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import logo from "../../public/Button - Icon (1).svg";
import arrow from "../../public/Button - Icon.svg";
import WarningIcon from "@material-ui/icons/Warning";
import contract_obj from "../../getTronweb";
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const Buypopup = ({
  open,
  handleClose,
  crypto,
  cryptoToTokens,
  buy,
  usdToSpend,
  accBalance,
  blockchain,
  factor,
}) => {
  // const [open, setmodal] = useState(false);
  const [bal, setbal] = useState(true);
  const [slug, setslug] = useState("");
  const [isavailable, setavail] = useState(true);
  const [country, setCountry] = useState("");
  const handleChange = e => {
    const { name, value } = e.target;
    setslug(value);
  };

  useEffect(async () => {
    getGeoInfo();
    let tradeobj =
      contract_obj.tronWebProvider &&
      (await contract_obj.tronWebProvider.trx.getAccount(
        contract_obj.accounts[0]
      ));
    console.log("- tradeobj:", tradeobj, "\n");
  }, []);
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => {
        let data = response.data;
        setCountry(data.country_name);
      })
      .catch(error => {
        setCountry("");
      });
  };
  return (
    <Card>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            <span className="custom">Let's do this.</span>

            <CloseIcon
              onClick={handleClose}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>
          <div className="midwrapper">
            <div className="lwrap"></div>
            <div className="rwrap">
              <div className="topd">
                <div className="cl">Buy Tokens</div>
                <div className="rd">{usdToSpend} USD</div>
              </div>
              <div className="slug-txt-input">
                <span className="slug-val">{crypto}</span>
                <div className="slug-check">{blockchain.toUpperCase()}</div>
              </div>

              <FormHelperText
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#7689B0",
                  fontFamily: "Poppins",
                  paddingLeft: "50px",
                }}
              >
                {/* 123.03 CETO Available today at no loss */}
              </FormHelperText>
              <div className="fee">
                <div className="f1">
                  <div className="cl">Transaction Fees</div>
                  <div className="slug-check">
                    {(crypto / 10).toFixed(3)} {blockchain.toUpperCase()}
                  </div>
                </div>

                <hr />
                <div className="f1">
                  <div className="cl">Net Tokens</div>
                  <div className="slug-check">
                    {blockchain == "eth"
                      ? (cryptoToTokens / factor).toFixed(6)
                      : (cryptoToTokens / factor).toFixed(6)}{" "}
                    {blockchain == "eth"
                      ? "ECETO"
                      : blockchain == "bnb"
                      ? "BCETO"
                      : "CETO"}
                  </div>
                </div>
              </div>

              <div className="cntner">
                {blockchain == "trx" &&
                  accBalance / (blockchain == "eth" ? factor : factor) <
                    parseInt(crypto) + 28 && (
                    <div className="warn-line">
                      {" "}
                      <WarningIcon
                        style={{ margin: "-6px auto", color: "#de3c3c" }}
                      ></WarningIcon>{" "}
                      <span>
                        Insufficient TRX in your wallet for transaction. Click{" "}
                        <a
                          target="_blank"
                          href="https://changenow.io/exchange?from=usd&to=crypto&fiatMode=true&link_id=cb26dc20354601"
                          style={{
                            textDecoration: "underline",
                            color: "#de3c3c",
                          }}
                        >
                          here
                        </a>{" "}
                        to change now.
                      </span>
                    </div>
                  )}

                <div className="action-div">
                  <GreenButton
                    style={{
                      background: "#ffffff",
                      color: "#691AAA",
                      border: "1px solid #691AAA",
                      marginRight: "15px",

                      width: "200px",
                    }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </GreenButton>
                  <GreenButton
                    style={{
                      background: " #691AAA",
                      color: "#ffffff",
                      width: "210px",
                    }}
                    onClick={() => {
                      handleClose();
                      buy();
                    }}
                  >
                    Confirm
                  </GreenButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Buypopup;
