import React, { useState } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./styles";
import exchange from "../../public/Exchange - Icon.svg";
import arrow from "../../public/Button - Icon.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import auto_ele from "../../public/auto_ele.png";
import twittericon from "../../public/twitter-icon.svg";
import discord from "../../public/Discord - Icon.svg";
import telegram from "../../public/Telegram - Icon.svg";
import tron from "../../public/Tron - Icon.png"
// import { FALSE } from "node-sass";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);

const SocialSharing = ({ open, setIsOpen }) => {
  return (
    <Card>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            An Elephant never forgets.
            <CloseIcon
              onClick={() => setIsOpen(false)}
              style={{ cursor: "pointer", color: "#009EE4" }}
            />
          </div>

          <div className="middiv">
            <div className="left">
              <img src="https://res.cloudinary.com/dgdjabqwr/image/upload/v1610798729/cd3d/Group_5262_1_wbfdez.png"></img>
              <div className = "d1"><img src = {tron}></img>3265.94 <span>TRX</span></div>
              <div className = "d2"><span>+992.21 (37%)</span> Last Month</div>
            </div>
            <div className="right"></div>
           
          </div>

          <div className = "bottom-div">
              <span className = "t1">Share:</span>
              <div className = "sec-1">
                <img src = {twittericon}></img>
                <span>twitter</span>
              </div>
              <div className = "sec-1">
                <img src = {discord}></img>
                <span>Discord</span>
              </div>
              <div className = "sec-1 last">
                <img src = {telegram}></img>
                <span>telegram</span>
              </div>
              <span className = "t1">Play for peanuts:</span>
              <span className = "t2">https://crystalelephant.net/ref/bib</span>


          </div>
        </div>
      </div>
    </Card>
  );
};

export default SocialSharing;
