import React from "react";

import { StyledCinemaDraftButton } from "./cinema-draft-button.styles";

const CinemaDraftButton = ({ children, ...otherProps }) => {
  return (
    <StyledCinemaDraftButton {...otherProps} >
      {children}
    </StyledCinemaDraftButton>
  );
};

export default CinemaDraftButton;
