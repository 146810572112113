import React, { useState, useEffect } from "react";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Card } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import arrow from "../../public/Button - Icon.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import web3_obj from "../../getWeb3";
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#009EE4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #009EE4",
        borderRadius: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#009EE4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#009EE4",
      },
    },
  },
})(TextField);
const ReferralModal = ({
  openModal,
  setOpen,
  ref_address,
  customize_link,
  balance,
  contract,
  custom,
  setUpdated,
  updated,
  history,
  txnLoading,
  setTxnLoading,
  ...props
}) => {
  const [bal, setbal] = useState();
  const [slug, setslug] = useState();
  const [hex_str, sethex_str] = useState("");
  const [accBalance, setAccBalance] = useState();

  const [isavailable, setavail] = useState();

  const handleChange = async e => {
    const { name, value } = e.target;
    setslug(value);
    console.log(value, "value");
    var str = "0x";
    var i;
    for (i = 0; i < value.length; i++) {
      str = str.concat(value.charCodeAt(i).toString(16));
    }
    // console.log(str.length);
    str = str.padEnd(66, "0");
    sethex_str(str);
    // console.log(str, value.charCodeAt(0), value.charCodeAt(0).toString(16));
    if (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb") {
      var referral_address = await web3_obj.contract_instace.methods
        .getReferralAddressForName(str)
        .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });
      console.log(referral_address, "ref", referral_address.toString(10));

      setavail(
        referral_address.toString() ==
          "0x0000000000000000000000000000000000000000"
      );
    } else {
      await contract.contract_instace
        .getReferralAddressForName(str)
        .call({
          from: contract.accounts[0],
          feeLimit: contract.feeLimit,
        })
        .then(function (res) {
          if (res) {
            setavail(
              res.toString() == "410000000000000000000000000000000000000000"
            );
          }
        });
    }
  };

  const customize = async () => {
    if (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb") {
      try {
        setTxnLoading(true);
        setOpen(false);

        var res = await web3_obj.refFunc(hex_str);
        setTxnLoading(false);

        setUpdated(updated + 1);
      } catch (error) {
        console.log(error);
        setTxnLoading(false);

        if (error.message.includes("User denied transaction signature")) {
          console.error("error caught right");
        }
      }
    } else {
      var res = await contract.refFunc(hex_str);
      console.log(res, "kk");
      setOpen(false);
      setUpdated(updated + 1);
    }
  };
  const check = async () => {
    if (
      (props.utils.blockchain == "eth" &&
        web3_obj.web3_instance &&
        web3_obj.accounts[0] &&
        web3_obj.accounts[0][0] &&
        (web3_obj.networkId == 1 || web3_obj.networkId == 3)) ||
      (props.utils.blockchain == "bnb" &&
        web3_obj.web3_instance &&
        web3_obj.accounts[0] &&
        web3_obj.accounts[0][0] &&
        (web3_obj.networkId == 97 || web3_obj.networkId == 56))
    ) {
      var c_balance = await web3_obj.web3_instance.eth.getBalance(
        web3_obj.accounts[0][0]
      );
      setAccBalance(c_balance);
    } else if (props.utils.blockchain == "trx") {
      window.tronWeb &&
        contract &&
        contract.accounts[0] &&
        (await window.tronWeb.trx
          .getBalance(contract.accounts[0])
          .then(result => {
            console.log(result, "balance");
            setAccBalance(result);
          }));
    }
  };
  useEffect(() => {
    custom ? setslug(custom) : setslug("");
    check();
  }, [custom]);
  useEffect(async () => {
    // setbal(balance / 1e6 > 100 ? true : false);
    if (props.utils.blockchain == "trx") {
      console.log("custom", custom);

      var obj = setInterval(async () => {
        if (contract.contract_instace) {
          clearInterval(obj);
          contract.contract_instace &&
            (await contract.contract_instace
              .myTokens()
              .call({
                from: contract.accounts[0],
                feeLimit: contract.feeLimit,
              })
              .then(function (res) {
                if (res) {
                  console.log(res.toString(), "TOKENS");
                  var tokens = parseInt(res.toString()) / 1e6;
                  tokens > 30 ? setbal(true) : setbal(false);
                }
              }));
        }
      }, 2);
    } else {
      if (web3_obj && web3_obj.accounts[0] && web3_obj.contract_instace) {
        var my_tokens = await web3_obj.contract_instace.methods
          .myTokens()
          .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });
        console.log(my_tokens, "my_tokens");
        parseFloat(my_tokens) / 1e18 > 30 ? setbal(true) : setbal(false);
      }
    }
  }, []);

  return (
    <Card>
      <div className={`modal ${openModal ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="upperdiv">
            <span className="custom">Customize Your Referral Link</span>

            <CloseIcon
              onClick={() => setOpen(false)}
              style={{
                cursor: "pointer",
                color: "#009EE4",
                marginTop: "-50px",
              }}
            />
          </div>

          {bal && accBalance / 1e6 > 7 ? (
            <>
              <div className="ref-div">
                <p className="cl s">Customize Link</p>

                <div className="domain">
                  https://www.crystalelephant.net/
                  {props.utils.blockchain == "bnb"
                    ? "bceto"
                    : props.utils.blockchain == "eth"
                    ? "eceto"
                    : "ceto"}
                  -ref/
                </div>
                <div className="slug">
                  <p className="cl l">Customize Link </p>
                  <CssTextField
                    fullWidth
                    name="referralCode"
                    FormHelperTextProps={false}
                    name="slug"
                    value={slug}
                    variant="outlined"
                    onChange={e => handleChange(e)}
                    InputProps={{
                      endAdornment: isavailable != null && (
                        <div className="slug-check">
                          {isavailable ? (
                            <>
                              {" "}
                              <span className="is-available">Available</span>
                              <CheckCircleIcon
                                style={{ marginLeft: "10px", color: "#009ee4" }}
                              />
                            </>
                          ) : (
                            <>
                              {" "}
                              <span className="is-available"> Unavailable</span>
                              <CancelIcon
                                style={{ marginLeft: "10px", color: "#CE457F" }}
                              />
                            </>
                          )}
                        </div>
                      ),
                    }}
                  />
                  {/* <FormHelperText
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      color: "#009EE4",
                      fontFamily: "Poppins",
                    }}
                  >
                    Try:
                    <span
                      style={{
                        color: "#7689B0",
                        marginLeft: "15px",
                      }}
                    >
                      leadtheherd01 , lead-the-herd , leadherd
                    </span>
                  </FormHelperText> */}
                </div>
              </div>
              <div className="action-div">
                <GreenButton
                  style={{
                    background: "#ffffff",
                    color: "#691AAA",
                    border: "1px solid #691AAA",
                    marginRight: "15px",
                  }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </GreenButton>
                <GreenButton
                  style={{
                    background: " #691AAA",
                    color: "#ffffff",
                  }}
                  onClick={() => customize()}
                >
                  Confirm
                </GreenButton>
              </div>
            </>
          ) : (
            <div className="middiv">
              <p className="warn">Oops!</p>
              <p className="inf">
                You must have at least {!bal ? "30 CETO" : "7 TRX"} in your
                wallet to customize your referral link
              </p>
              {!bal && (
                <GreenButton
                  style={{ background: "#002E8C", color: "#ffffff" }}
                  onClick={() => history.replace("/buy")}
                >
                  Get More CETO
                </GreenButton>
              )}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ReferralModal;
